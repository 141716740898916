import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 30px 20px 50px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #ffffff8f;
  color: #000;

  border-radius: 8px;
  box-shadow: 4px 4px 4px #ccc;
  border: 1px solid #ccc;

  h2 {
    margin-bottom: 30px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: #f09f30;

    &:hover {
      color: #1E2B6E;
    }
  }
`;
