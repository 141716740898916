import styled from 'styled-components';
import { shade } from 'polished';

import background from '../../assets/bg_units_projects.png';

export const Container = styled.main`
	width: 100vw;
	overflow-x: hidden;
	background-color: #fff;

	#icmaqWap {
		@media only screen and (max-width: 576px) {
			margin-top: -50px;
		}
	}
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background: url(${background});
		background-position: 0px 25px;
	}

	@media only screen and (min-width: 768px) {
		background-position: initial;
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
		text-align: center;

		@media screen and (max-width: 576px) {
			padding: 10px 0px;
		}
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;

		background-color: #1E2B6E;
	}

	div.subtitle {
		width: 300px;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		align-items: center;

		span {
			& + h2 {
				margin-bottom: 30px;
			}
		}
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}

	&.clearBottom {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	& + section {
		margin-top: 40px;
	}
`;

export const LogoWap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 40px 0 0;

	img {
		width: 55%;
	}

	@media screen and (min-width: 768px) {
		margin: 40px 0 20px;

		img {
			width: 40%;
			max-width: 793px;
		}
	}

	@media screen and (min-width: 1532px) {
		margin: 60px 0 210px;
	}

	@media screen and (min-width: 1570px) {
		margin: 60px 0 230px;
	}


	@media screen and (min-width: 1680px) {
		margin: 60px 0 300px;
	}
`;

export const Section = styled.section`
	margin-bottom: 50px;
	display: flex;
	place-content: center;
`;

export const VideoWapContent = styled.div`
	width: 100%;
	margin-top: 50px;

	@media only screen and (max-width: 576px) {
		margin-top: 40px;
	}

	display: flex;
	align-items: center;
	justify-content: center;

	div {
		position: relative;
		width: 60%;
		max-width: 684px;
		padding-bottom: 39.37%;
		margin: 20px 0;

		@media only screen and (max-width: 992px) {
			width: 100%;
			padding-bottom: 60%;
		}

		@media only screen and (max-width: 576px) {
			width: 100%;
			padding-bottom: 60%;
		}

		@media only screen and (min-width: 1200px) {
			max-width: 684px;
			height: 449px;
			padding-bottom: 0px;
		}
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		border: none;
	}
`;

export const Content = styled.div`
	width: 100%;
	max-width: 1511px;
	margin: auto auto;
`;

export const ScientificPosters = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h3 {
		margin: 35px 0 10px;
		text-align: center;
	}

	.articleScientificPoster {
		width: 100%;
		min-height: 70px;
		padding: 10px 10px 10px 20px;
		margin-bottom: 15px;

		background: #ffffff8f;
		border-left: 5px solid #F09F30;
		border-right: 5px solid #F09F30;
		border-radius: 8px;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);

		a {
			width: 100%;
			height: 100%;
			text-decoration: unset;
			font-weight: 400;
			color: #000;

			& > div {
				display: flex;
				flex-direction: column;
			}

			& > div > h4 {
				text-decoration: underline;
				color: #1E2B6E;
				margin-bottom: 10px;
			}
		}

		&:hover {
			background: #cdb47973;
			position: relative;
			top: 5px;

			h4 {
				color: #599252;
			}
		}

		@media only screen and (max-width: 576px) {
			margin-bottom: 30px;
		}
	}

	.hiddenPosters {
		display: none;
	}

	.showPosters {
		display: flex;
		flex-direction: column;
	}

	button {
		width: 200px;
		padding: 13px;
		margin-top: 17px;
		color: #fff;
		background: #1E2B6E;
		border: none;
		border-radius: 8px;

		&:hover {
			color: #F09F30;
			background: ${shade(0.4, "#1E2B6E")}
		}
	}

`;
