import React, { Suspense } from 'react';
import { BrowserRouter} from 'react-router-dom';

import LanguageProvider from './hooks/LanguageProvider';

import Routes from './routes';

import GlobalStyle from './styles/global';

import {initGA} from './services/analytics';

const App: React.FC = () => {
  initGA();

  return(
    <Suspense fallback="loading">
      <LanguageProvider>
        <BrowserRouter>
          <GlobalStyle />
          <Routes />
        </BrowserRouter>
      </LanguageProvider>
    </Suspense>
  );
}

export default App;
