import React, { useState, useCallback, useEffect } from 'react';
import { format } from 'date-fns';
// import Pagination from "react-js-pagination";
import { RiNewspaperLine, RiCalendar2Line } from 'react-icons/ri'

import { useLanguage } from '../../hooks/LanguageProvider';
import { GApageView } from '../../services/analytics';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import LoadingPage from '../../components/LoadingPage';

import {
	Container,
	Bg,
	Title,
	CardsContainer,
	Cards,
} from './styles';

interface ICardProp {
	id: number;
	name: string;
	cover?: string;
	link: string;
	news_channel: string;
	publication_date: Date;
}

const Press: React.FC = () => {
	const [dataActivePage, setDataActivePage] = useState<ICardProp[]>();
	const [loadingPage, setLoadingPage] = useState(true);
	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Imprensa"},
		{language:"en-US", title:"Press"},
		{language:"cn", title:"媒体"}
	];

	const noImgs = [
		{language:"pt-BR", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_pt-BR.jpg`},
		{language:"en-US", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_en-US.jpg`},
		{language:"cn", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_cn.jpg`}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);
	const noImg = noImgs.filter(el => el.language === languageProvider);

	const getData = useCallback((page = 1) => {
		api.get(`/press`).then((response) => {
			setDataActivePage(response.data);
			setLoadingPage(false);
		});
	},[]);

	useEffect(()=> {
		getData()
		GApageView("midia/imprensa")
	},[getData]);

	if(loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<Bg>
				<Title>
					<h1>{title[0]?.title}</h1>
					<span className="dividerBlue"></span>
				</Title>

				<CardsContainer>
					<ResponsiveContainer>
						{
							dataActivePage?.map((data) => (
								<a
									key={data.id}
									href={data.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Cards>
										<div className="cover">
											<img src={data.cover ? data.cover : noImg[0]?.link} alt={data.name}/>
										</div>

										<div className="textCard">
											<h2>{data.name}</h2>

											<span>
												<RiNewspaperLine size={18}/>

												{data.news_channel}
											</span>

											<span>
												<RiCalendar2Line size={18}/>

												{format(
													new Date(new Date(data.publication_date).valueOf() + new Date(data.publication_date).getTimezoneOffset() * 60 * 1000), 'dd/MM/yyyy'
												)}
											</span>
										</div>
									</Cards>
								</a>
							))
						}
					</ResponsiveContainer>
				</CardsContainer>

				<Footer />
			</Bg>
		</Container>
	);
}

export default Press;
