import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLanguage } from '../../hooks/LanguageProvider';
import { GApageView } from '../../services/analytics';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import NewsCard from '../../components/NewsCard';
import NewsContent from '../../components/NewsContent';
import LoadingPage from '../../components/LoadingPage';

import { Container, Bg, Title, Cards } from './styles';

interface ICardProp {
	id: number;
	news_ref_id: number;
	name: string;
	cover?: string;
	slug: string;
	description: string;
	publication_date: Date;
}

interface IUseParamsProp {
	id: string;
}

const News: React.FC = () => {
	const [newsList, setNewsList] = useState<ICardProp[]>();
	const [loadingPage, setLoadingPage] = useState(true);

	const { id } = useParams<IUseParamsProp>();

	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Notícias"},
		{language:"en-US", title:"News"},
		{language:"cn", title:"新闻"}
	];

	const noImgs = [
		{language:"pt-BR", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_pt-BR.jpg`},
		{language:"en-US", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_en-US.jpg`},
		{language:"cn", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_cn.jpg`}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);
	const noImg = noImgs.filter(el => el.language === languageProvider);

	const getData = useCallback((page = 1) => {
		let languagePage = languageProvider === "pt-BR" ? "pt-BR" : "en-US";

		api.get(`/news/lang/${languagePage}`).then((response) => {
			setNewsList(response.data);
			setLoadingPage(false);
		});
	},[languageProvider]);

	useEffect(()=> {
		getData()
		GApageView("midia/noticias")
	},[getData]);

	if(loadingPage) {
		return <LoadingPage />;
	}

	return (
		<>
			<Header />

			<Bg>
				<Title>
					<h1>{title[0]?.title}</h1>
					<span className="dividerBlue"></span>
				</Title>

				<Container>
					<ResponsiveContainer>
						<Cards>
							{
								!id
								? (<>
									{newsList?.map((data) => (
										<NewsCard
											key={data.id}
											id={data.news_ref_id}
											cover={data.cover ? data.cover : noImg[0]?.link}
											title={data.name}
											slug={data.slug}
											publicationDate={data.publication_date}
										/>
									))}
								</>)
								: <NewsContent id={id}/>
							}
						</Cards>
					</ResponsiveContainer>
				</Container>

				<Footer />
			</Bg>
		</>
	);
}

export default News;
