import styled from 'styled-components';

const bgHome = `${process.env.REACT_APP_URL_IMG}/pages/home/bg-lab-reduce.png`;

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	background: url(${bgHome}) no-repeat;
	background-size: cover;
	background-attachment: fixed;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 788px) {
		height: calc(100vh - 58px);
	}

	.modalContainer.on {
		display: flex;
	}

	.modalContainer {
		width: 100%;
		height: 100%;
		cursor: default;
		background: #000000a8;

		position: fixed;
		top: 0;
		z-index: 10;

		display: none;
		align-items: center;
		justify-content: center;
	}

	.modalUnit {
		width: 95%;
		max-width: 950px;
		height: 75%;
		padding: 20px 15px 20px 20px;
		border-radius: 8px;
		background-color: #F0F0F5;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
		overflow-y: initial;
		overflow-x: hidden;
		position: absolute;

		.closeModal {
			padding: 10px 13px;
			border-radius: 50%;
			border: none;
			background-color: #F09F30;
			color: #f0f0f5;
			font-size: 22px;
			cursor: pointer;
			z-index: 12;

			position: fixed;
			top: 100px;
			margin-left: 901px;

			@media (max-width: 992px){
				right: 9px;
				margin-left: 0;
			}

			&:hover {
				font-size: 23px;
				font-weight: bold;
				color: #0F2A9A;
			}
		}
	}
`;

export const TextHome = styled.main`
  width: 730px;
  padding: 2%;
  background-color: #f0a030b9;

  p {
    color:#1e2b6e;
    font-size: 1.2em;
    line-height: 1.3em;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 720px) {
    width: 90%;
  }
`;
