import React, { useState, useCallback, useEffect } from 'react';
import { RiArrowRightCircleLine, RiArrowLeftCircleLine, RiArrowRightLine } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import { GApageView } from '../../services/analytics';

import 'pure-react-carousel/dist/react-carousel.es.css';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingPage from '../../components/LoadingPage';

import { Container, Bg, Title, Timeline, TimelineContent, CustomResponsiveContainer } from './styles';

interface IResponseProp {
	id: number;
	units_ref_id: number;
	name: string;
	start_year: number;
	finish_year?: number;
	cover?: string;
	presentation: string;
	description: string;
}

interface IUnitProp {
	id: number;
	content_ref_id: number;
	name: string;
	start_year: number;
	finish_year?: number;
	cover?: string;
	presentation: string;
	description: string;
}

interface GalleryProp {
	id: number;
	id_content: number;
	page: string;
	folder: string;
	picture: string;
	ext: string;
	subtitle?: string;
}

const Units: React.FC = () => {
	const [showModal, setShowModal] = useState(true);
	const [loadingPage, setLoadingPage] = useState(true);

	const [units, setUnits] = useState<IUnitProp[]>([]);
	const [currentGallery, setCurrentGallery] = useState<GalleryProp[]>();
	const [contentModal, setContentModal] = useState<IUnitProp>();

	const { language: languageProvider } = useLanguage();

	const titlesPage = [
		{ language: 'pt-BR', title: 'Unidades' },
		{ language: 'en-US', title: 'Units' },
		{ language: 'cn', title: 'Units' },
	];

	const showMoreDict = [
		{ language: 'pt-BR', name: 'Saiba Mais' },
		{ language: 'en-US', name: 'Show More' },
		{ language: 'cn', name: 'Show More' },
	];

	const currentDict = [
		{ language: 'pt-BR', name: 'Atual' },
		{ language: 'en-US', name: 'Current' },
		{ language: 'cn', name: 'Current' },
	];

	const title = titlesPage.filter((el) => el.language === languageProvider);
	const showMore = showMoreDict.filter((el) => el.language === languageProvider);
	const currentTranslate = currentDict.filter((el) => el.language === languageProvider);

	const handleModal = useCallback((unit: IUnitProp) => {
		let languagePage = languageProvider === 'cn' ? 'en-US' : languageProvider;

		let modalContainer = document.querySelector('.modalContainer');

		if (!showModal) {
			setCurrentGallery([]);
		} else {
			api.get(`/galleries/lang/${languagePage}/units/${unit.content_ref_id}`)
				.then((response) => {
					setCurrentGallery(response.data);
				}
			);
		}

		modalContainer?.classList.toggle('on', showModal);

		document.body.style.overflow = showModal ? 'hidden' : 'initial';

		setContentModal(unit);
		setShowModal(!showModal);
	}, [languageProvider, showModal]);

	useEffect(() => {
		if (languageProvider) {
			let languageThisPage = languageProvider === 'cn' ? 'en-US' : languageProvider;

			api.get(`/units/lang/${languageThisPage}`).then((response) => {
				const data: IResponseProp[] = response.data;
				const dataModified: IUnitProp[] = [];

				data.forEach((d) => {
					let dataFormated = {
						id: d.id,
						content_ref_id: d.units_ref_id,
						name: d.name,
						start_year: d.start_year,
						finish_year: d.finish_year,
						cover: d.cover,
						presentation: d.presentation,
						description: d.description,
					};

					dataModified.push(dataFormated);
				});

				setUnits(dataModified);

				setLoadingPage(false);
			});
		}

		GApageView('institucional/unidades');
	}, [languageProvider]);

	if (loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<Bg>
				<Title>
					<h1>{title[0]?.title}</h1>
					<span className="dividerBlue"></span>
				</Title>

				<Timeline>
					<CustomResponsiveContainer>
						{units.map((unit) => (
							<TimelineContent key={unit.id}>
								<div className="timelineLeft">
									<span className="yearsUnit">
										{unit.start_year} - {unit.finish_year ?? currentTranslate[0].name}
									</span>
								</div>

								<button className="timelineRight" onClick={() => handleModal(unit)}>
									<span className="yearsUnitMobile">
										{unit.start_year} - {unit.finish_year ?? currentTranslate[0].name}
									</span>

									<div className="unitCard">
										<h2>{ReactHtmlParser(unit.name)}</h2>

										<div className="unitContent">
											<div className="unitImg">
												<img src={unit.cover} alt="aasdap" />
											</div>

											<div className="unitText">
												{ReactHtmlParser(unit?.presentation ?? '')}

												<div className="showMore">
													<span>
														{showMore[0]?.name}
														<RiArrowRightLine size={18} />
													</span>
												</div>
											</div>
										</div>
									</div>
								</button>

								<div className="modalContainer">
									<div className="modalUnit">
										<button className="closeModal" onClick={() => handleModal(unit)}>
											X
										</button>

										<div className="contentUnit">
											<span className="yearsUnit">
												{contentModal?.start_year} - {contentModal?.finish_year ?? currentTranslate[0].name}
											</span>

											<h2>{ReactHtmlParser(contentModal?.name ?? '')}</h2>

											<div className="textUnit">
												{ReactHtmlParser(contentModal?.description ?? '')}

												<div className="containerSlider">
													<CarouselProvider
														naturalSlideWidth={800}
														naturalSlideHeight={600}
														totalSlides={currentGallery?.length ?? 0}
														infinite
														isIntrinsicHeight
													>
														<Slider>
															{currentGallery?.map((gallery, index) => (
																<Slide key={gallery.id} index={index}>
																	<div className="currentSlide">
																		<Image
																			src={`${process.env.REACT_APP_URL_IMG}/pages/units/${gallery.folder}/${gallery.picture}${gallery.ext}`}
																			alt={gallery.subtitle}
																			hasMasterSpinner
																		/>

																		<div className="buttonsController">
																			<ButtonBack>
																				<RiArrowLeftCircleLine size={24} />
																			</ButtonBack>
																			<span>
																				{index + 1} / {currentGallery.length}
																			</span>
																			<ButtonNext>
																				<RiArrowRightCircleLine size={24} />
																			</ButtonNext>
																		</div>

																		<div className="carousel-caption">
																			<p>{gallery.subtitle}</p>
																		</div>
																	</div>
																</Slide>
															))}
														</Slider>
													</CarouselProvider>
												</div>
											</div>
										</div>
									</div>
								</div>
							</TimelineContent>
						))}
					</CustomResponsiveContainer>
				</Timeline>

				<Footer />
			</Bg>
		</Container>
	);
};

export default Units;
