import React, { useState, useCallback, useEffect } from 'react';
import { RiArticleLine, RiBarChartBoxLine } from 'react-icons/ri'
import { GApageView } from '../../services/analytics';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import LoadingPage from '../../components/LoadingPage';

import {
	Container,
	Bg,
	Title,
	CardsContainer,
	Cards,
} from './styles';

interface ICardProp {
	id: number;
	name: string;
	cover?: string;
	publication_date: Date;
	link: string;
	reference: string;
	metrics_link?: string;
	doi?: string;
}

const ScientificPublications: React.FC = () => {
	const [dataActivePage, setDataActivePage] = useState<ICardProp[]>();
	const [loadingPage, setLoadingPage] = useState(true);
	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Produção Científica"},
		{language:"en-US", title:"Scientific Publications"},
		{language:"cn", title:"科学生产"}
	];

	const noImgs = [
		{language:"pt-BR", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_pt-BR.jpg`},
		{language:"en-US", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_en-US.jpg`},
		{language:"cn", link:`${process.env.REACT_APP_URL_IMG}/sem-imagem_200x180_cn.jpg`}
	];

	const articlesButton = [
		{language:"pt-BR", name: "Artigo"},
		{language:"en-US", name: "Article"},
		{language:"cn", name: "文章"}
	];

	const metricsButton = [
		{language:"pt-BR", name: "Métrica"},
		{language:"en-US", name: "Metric"},
		{language:"cn", name: "公制"}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);
	const noImg = noImgs.filter(el => el.language === languageProvider);
	const articleButton = articlesButton.filter(el => el.language === languageProvider);
	const metricButton = metricsButton.filter(el => el.language === languageProvider);

	const getData = useCallback(() => {
		api.get(`/scientific-publications`).then((response) => {
			setDataActivePage(response.data);
			setLoadingPage(false);
		});
	}, []);

	useEffect(()=> {
		getData()
		GApageView("producao-cientifica")
	},[getData]);

	if(loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<Bg>
				<Title>
				<h1>{title[0]?.title}</h1>
				<span className="dividerBlue"></span>
				</Title>

				<CardsContainer>
					<ResponsiveContainer>
						{
							dataActivePage?.map((data) => (
								<Cards key={data.id}>
									<div className="cover">
										<img src={data.cover ? data.cover : noImg[0]?.link} alt={data.name}/>
									</div>

									<div className="containerMobile">
										<div className="textCard">
											<h2>{data.name}</h2>
											<span>{data.reference}</span>
										</div>

										<div className="linkArticle">
											<a href={data.link} target="_blank" rel="noopener noreferrer">
												<div className="linksButtom">
													<RiArticleLine size={35} />
													<span>{articleButton[0]?.name}</span>
												</div>
											</a>

											<a href={data.metrics_link} target="_blank" rel="noopener noreferrer">
												<div className="linksButtom">
													<RiBarChartBoxLine size={35} />
													<span>{metricButton[0]?.name}</span>
												</div>
											</a>
										</div>
									</div>
								</Cards>
								))
							}
						</ResponsiveContainer>
					</CardsContainer>

					<Footer />
				</Bg>
			</Container>
			);
		}

		export default ScientificPublications;
