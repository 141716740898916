import React, {useCallback, useState, useEffect} from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { GApageView } from '../../services/analytics';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import Input from '../../components/Form/Input';
import TextArea from '../../components/Form/TextArea';

import {
	Container,
	ContainerContent,
	ContainerContact,
	ContactInfo,
	TitlePage,
	Spinner
} from './styles';

interface ContactUsData {
	name: string;
	email: string;
	phone: string;
	message: string;
}

const ContactUs: React.FC = () => {
	const [loadingMail, setLoadingMail] = useState(false);
	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Contato"},
		{language:"en-US", title:"Contact Us"},
		{language:"cn", title:"接觸"}
	];

	const fieldsFormPage = [
		{language:"pt-BR", name:"Nome Completo", email:"E-mail", phone: "Telefone", msg:"Mensagem", button: "ENVIAR" },
		{language:"en-US", name:"Full Name", email:"Email", phone: "Phone", msg:"Message", button: "SEND"},
		{language:"cn", name:"名称", email:"电邮", phone: "电话", msg:"信息", button: "提交"}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);
	const fieldFormPage = fieldsFormPage.filter(el => el.language === languageProvider);

	const handleSubmit = useCallback(
		async (data: ContactUsData, { reset }: { reset: () => void }) => {
			setLoadingMail(true);

			try {
				const schema = Yup.object().shape({
					name: Yup.string().required(),
					email: Yup.string().email(),
					phone: Yup.string().max(20),
					message: Yup.string().min(5).required(),
				});

				await schema.validate(data, {
					abortEarly: false,
				});

				await api.post('/messages',{
					name: data.name,
					email: data.email,
					phone: data.phone,
					message: data.message,
				});

				alert("Mensagem enviada com sucesso.");
				reset();
			} catch(err) {
				if(err instanceof Yup.ValidationError) {
					alert("Houve um erro ao enviar a mensagem.");
				}
			}

			setLoadingMail(false);
		},[]);

		useEffect(()=> {
			GApageView("contato");
		},[]);

	return (
		<Container>
			<Header />

			<ResponsiveContainer>
				<ContainerContent>
					<ContainerContact>
						<ContactInfo>
							<div className="containerContactInfo">
								<div className="contactInfo">
									<TitlePage>
										<h1>{title[0]?.title}</h1>
										<span className="dividerOrange"></span>
									</TitlePage>

									<div className="mailContact">
										<span>contato@aasdap.org.br</span>
									</div>
								</div>
							</div>
						</ContactInfo>

						<div className="form">
							<Form onSubmit={handleSubmit} placeholder="">
								<Input name="name" placeholder={fieldFormPage[0]?.name} required />
								<Input name="email" type="email" placeholder={fieldFormPage[0]?.email} required />
								{ languageProvider !== "cn" && (<Input name="phone" type="text" placeholder={fieldFormPage[0]?.phone} />)}
								<TextArea name="message" placeholder={fieldFormPage[0]?.msg} required />

								<button type="submit">
									{
										!loadingMail
										? fieldFormPage[0]?.button
										: (<Spinner />)
									}
								</button>
							</Form>
						</div>
					</ContainerContact>
				</ContainerContent>
			</ResponsiveContainer>
			<Footer />
		</Container>
	);
}

export default ContactUs;
