import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GApageView } from '../../services/analytics';

import { useLanguage } from '../../hooks/LanguageProvider';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';

import {
	Bg,
	Container,
	Content,
	Logo,
	Section,
	Title,
} from './styles';

const NIABS: React.FC = () => {
	const {language: languageProvider } = useLanguage();

	const contents = [
		{
			language: "pt-BR",
			publication: "05/03/2024",
			title: "Miguel Nicolelis Anuncia Parceria para Criação do Polo de Neurotecnologia San Raffaele em Milão",
			content: `
				<h3>Instituto Nicolelis de Estudos Avançados do Cérebro anuncia parceria com Hospital San Raffaele e Universidade Vita-Salute San Raffaele para criação de um Polo de Neurotecnologia em Milão na Itália</h3><br/>

				<p>O recém-criado Instituto Nicolelis de Estudos Avançados do Cérebro, vinculado à Associação Alberto Santos Dumont para Apoio à Pesquisa (<a href="https://aasdap.org.br" target="_blank">www.aasdap.org.br</a>) anunciou hoje o lançamento de uma parceria de longo prazo com as organizações italianas Hospital IRCCS San Raffaele e Universidade Vita-Salute San Raffaele, para estabelecer um Polo de Neurotecnologia no campus destas instituições em Milão. Resultado de um processo de planejamento de dois anos, que incluiu a elaboração de um Plano Diretor, o Polo de Neurotecnologia San Raffaele será a primeira iniciativa deste tipo na Europa, concentrada na  implantação de larga escala de neurotecnologias e protocolos de neuroreabilitação, baseados na aplicação de interfaces cérebro-máquina não invasivas (<b>ICMn</b>) novas e genéricas (<i>Figura 1</i>). Essas ICMn serão a fundação de múltiplos protocolos de neuroreabilitação e terapias para tratamento de pessoas sofrendo de várias doenças neurológicas, tais como lesão medular, doença de Parkinson, derrames, esclerose múltipla e epilepsia crônica.</p><br/>

				<p>Estima-se que mais de 1 bilhão de pessoas no mundo todo são acometidas por algum tipo de disfunção cerebral, incluindo doenças neurológicas e psiquiátricas. Até os anos de 2030, o custo global para tratar desse número massivo de pessoas pode chegar próximo a seis trilhões de dólares. Portanto, terapias e tecnologias de inovadoras, clinicamente seguras e eficientes, e de baixo custo são necessárias para atender às necessidades de longo prazo dessas pessoas. Nos últimos 20 anos, a demonstração recorrente de que é perfeitamente possível conectar um cérebro humano à ferramentas robóticas, eletrônicas, ou virtuais, através das interfaces cérebro-máquina para criar aparatos neuroprostéticos e estabelecer protocolos de neuroreabilitação inovadores tem surgido como uma potente abordagem para enfrentar este enorme desafio global no cuidado à saúde. Acessar e se beneficiar do potencial clínico das ICM não invasivas será a principal missão do Polo de Neurotecnologia San Raffaele.</p><br/>

				<p>Inicialmente, o Polo de Neurotecnologia San Raffaele terá a missão central de prover aos pacientes em toda Itália e Europa o acesso integral aos modernos protocolos de neuroreabilitação e neurotecnologias desenvolvidas originalmente pela equipe brasileira multidisciplinar liderada pelo Dr. Miguel Nicolelis, neurocientista e Professor Emérito da Duke University Medical Center, nos Estados Unidos, e presidente do Instituto Nicolelis de Estudos Avançados do Cérebro. Ao lado do Dr. John Chapin, Nicolelis criou, no final dos anos 90, a abordagem neurofisiológica que eles denominaram interface cérebro-máquina. Nos últimos 25 anos, Nicolelis e sua equipe de pesquisadores nos EUA e Brasil desenvolveram múltiplas aplicações clínicas, baseadas em diferentes arranjos de ICM, combinadas com múltiplas ferramentas derivadas dos campos da realidade virtual e robótica. Além da autoria do Plano Diretor - elaborado em colaboração com o Dr. Alan Rudolph, e que guiará as atividades do Polo Neurotecnológico - Nicolelis assumirá a posição de Professor Visitante da Universidade Vita-Salute San Raffaele, e co-liderará a colaboração Brasileira-Italiana.</p><br/>

				<p>Desenvolvidos originalmente para a demonstração do primeiro <a href="https://www.youtube.com/watch?v=ymyTEgi85Yg" target="_blank">exoesqueleto de membros inferiores controlado pelo cérebro</a>, que possibilitou que um jovem com paraplegia desferisse o chute inicial da Copa do Mundo da FIFA em 2014, os protocolos de neuroreabilitação que serão implementados no Centro de Neuroreabilitação do Polo de Neurotecnologia San Raffaele são baseados na combinação de ICM não invasivas, realidade virtual, robótica, e mais recentemente em técnicas de neuromodulação não-invasivas (<a href="https://www.nature.com/articles/srep30383" target="_blank">Donati et al, 2016</a>; <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0206464" target="_blank">Shokur et al, 2018</a>; <a href="https://www.nature.com/articles/s41598-019-43041-9" target="_blank">Selfslagh et 2019</a>; <a href="https://www.nature.com/articles/s41598-022-24864-5" target="_blank">Nicolelis et al 2022</a>).</p><br/>

				<p>De acordo com Nicolelis: “Estabelecer esta colaboração com um dos hospitais mais prestigiosos do mundo é a realização de um sonho. Esta parceria internacional permitirá às pessoas acometidas de doenças neurológicas o acesso a tecnologias de ICM não invasivas de última geração, que são seguras, de baixo custo, e altamente eficazes. Estou certo de que meu grande amigo John Chapin, que criou esta tecnologia comigo, se orgulharia deste anúncio. Nós esperamos alcançar um grande número de pacientes nos próximos anos, e demonstrar categoricamente que as ICM não-invasivas, combinadas a outras tecnologias inovadoras e ferramentas de dados, se tornarão a principal abordagem para tratar doenças neurológicas e psiquiátricas muito em breve."</p><br/>

				<p>O Professor Enrico Gherlone, Reitor da Universidade Vita-Salute San Raffaele comenta: “É com prazer que anunciamos o início do projeto estratégico Polo Neurotecnológico, resultado da parceria que estabelecemos com o Professor Nicolelis e sua equipe. Após o período de dois anos de implementação do Plano Diretor, o Polo Neurotecnológico está pronto para entrar em sua fase operacional, garantindo que nos próximos anos nós alcançaremos nossa visão estratégica: as neurotecnologias como uma medicina avançada, nova e mandatória, com aplicações potenciais na melhora das saúdes motora e cognitiva, e também na abordagem de doenças neurológicas e psiquiátricas. Sem dúvida, tudo isso representa uma oportunidade crucial, especialmente para nossos residentes, proporcionando a eles a oportunidade de interagir com tecnologia de ponta, única na Europa”.</p><br/>

				<p>Marco Centenari, Engenheiro e CEO do Hospital IRCCS San Raffaele, comenta: “O novo Polo Neurotecnológico representa um dos principais projetos estratégicos do nosso Instituto. A colaboração com o Professor Nicolelis é uma prova tangível do nosso compromisso em apoiar um esforço de longo prazo, que visa desenvolver a medicina cada vez mais translacional. O grupo de Nicolelis contará com o apoio de uma equipe de excelentes neurologistas do nosso hospital, liderada pelo professor Massimo Filippi, que possui expertise reconhecida internacionalmente no teste de novas terapias para doenças neuroinflamatórias e neurodegenerativas, bem como no desenvolvimento de novos biomarcadores para prevenir e tratar tais doenças de forma mais eficaz. Estamos confiantes de que, num futuro próximo, seremos capazes de avançar ainda mais no campo transdisciplinar, como aquele que apoia o desenvolvimento neurotecnológico, uma ajuda valiosa para um envelhecimento mais saudável do sistema nervoso”.</p><br/>

				<p>Em adição a um Centro de Neuroreabilitação, a fase inicial do Polo de Neurotecnologia San Raffaele também prevê o estabelecimento de um Laboratório de Neurodados e uma Iniciativa de Neurotelemedicina. Além destes, uma série de iniciativas educacionais também estão planejadas, incluindo a criação de um Programa de Neuroreabilitação para profissionais da saúde, um curso global em ICM, um Programa de bolsas de graduação, e, eventualmente, um Programa de Graduação em Neuroreabilitação de ICM.</p><br/>

				<figure style="text-align: center;">
					<img src="https://img.aasdap.org.br/pages/niabs/picture1.jpeg" alt="ICM genérica não-invasiva" style="max-width: 100%; height: auto;">
					<figcaption>Figura 1 – ICM genérica não-invasiva concebida pelo Dr. Nicolelis e sua equipe de pesquisadores, que será usada na neuroreabilitação de múltiplas doenças neurológicas no Centro de Neuroreabilitação e no Polo Neurotecnológico San Raffaele.</figcaption>
				</figure><br/>

				<p>Em relação ao estabelecimento da parceria clínico-científica internacional, singular e abrangente lançada hoje, o Dr. Alan Rudolph disse:</p><br/>

				<p>“Nós estamos entusiasmados com o lançamento deste programa, que vai ajudar pessoas acometidas por doenças neurológicas e expandir nosso trabalho em técnicas de reabilitação inovadoras que utilizam novos conceitos e protocolos para auxiliar pessoas que precisam de tratamento, e também treinar uma nova geração de profissionais”.</p><br/>

				<p>A parceria com o Hospital e Universidade San Raffaele é a primeira iniciativa desenvolvida como parte do Projeto “Treat 1 Billion” idealizado pelo Instituto Nicolelis de Estudos Avançados do Cérebro. O objetivo central desta iniciativa é disseminar globalmente múltiplas neurotecnologias e terapias baseadas em interfaces cérebro- máquina não invasivas que sejam seguras, eficientes e de baixo custo de tal sorte que elas possam atingir milhões de pacientes sofrendo de doenças cerebrais espalhados por todo o mundo. Para alcançar este objetivo de longo prazo, o Instituto Nicolelis tem como meta estabelecer polos regionais com parceiros de reconhecida competência médica em todos os continentes.</p><br/>

				<h3>Informações adicionais serão dadas em breve.</h3><br/>
			`
		},
		{
			language:"en-US",
			publication: "March 5th, 2024",
			title: "MAJOR NEUROTECH HUB IN MILAN ANNOUNCED",
			content: `
				<h3>The IRCCS San Raffaele Hospital and the Vita-Salute San Raffaele University announced today a partnership with the Nicolelis Institute for Advanced Brain Studies from Brazil to establish the San Raffaele Neurotech Hub in Milan</h3><br/>

				<p>The IRCCS San Raffaele Hospital and the Vita-Salute San Raffaele University announced the launch of a long-term partnership with the recently established Nicolelis Institute for Advanced Brain Studies of the Brazilian Alberto Santos Dumont Association for Research Support (AASDAP, <a href="https://aasdap.org.br" target="_blank">www.aasdap.org.br</a>) aimed at creating a state-of-the- art Neurotech Hub on their campus in the city of Milan. Resulting from a two-year planning process, that included the development of a comprehensive Master Plan, the San Raffaele Neurotech Hub will be the first initiative of this kind in Europe focused on deploying large-scale modern neurotechnologies and neurorehabilitation protocols, based on the employment of a novel and generic non-invasive brain-machine interface (<b>nBMI</b>) approach (<i>Figure 1</i>). This nBMI will serve as the foundation of multiple neurorehabilitation protocols and therapies targeting patients suffering from a variety of neurological disorders, such as spinal cord injury, Parkinson’s disease, stroke, multiple sclerosis, and chronic epilepsy.</p><br/>

				<p>It is estimated that more than one billion worldwide suffer from some sort of brain disorder, including both neurologic and psychiatric diseases. By the 2030s, the global cost for treating this massive number of patients may reach close to $6 trillion. Therefore, new safe, clinically efficient, and affordable therapies and neurorehabilita technologies are needed to address the long-term needs of such patients. For the past 20 years, the repeated demonstration that it is possible to seamlessly link human brains to robotic, electronic, or virtual devices, through so-called brain-machine interfaces (BMIs), to create neuroprosthetic devices and establish innovative neurorehabilitation protocols, has emerged as a powerful new therapeutic and neurorehabilitation approach to match this major health care challenge worldwide. Assessing and taking advantage of the clinical potential of nBMIs will be the main mission of the recently established San Raffaele Neurotech Hub.</p><br/>

				<p>Initially, the San Raffaele Neurotech Hub will take off with the central mission to provide patients all over Italy and Europe with full access to modern neurorehabilitation protocols and neurotechnologies, originally developed by a multidisciplinary Brazilian research team led by Dr. Miguel Nicolelis, a neuroscientist and Professor Emeritus at Duke University Medical Center in the United States. In the late 1990s, Dr. Nicolelis, together with Dr. John Chapin, invented the neurophysiological approach that they named brain-machine interfaces. For the past 25 years, Dr. Nicolelis and his research teams in the US and Brazil have developed multiple clinical applications based on different architectures of BMIs combined with numerous tools derived from the fields of virtual reality and robotics. Dr. Nicolelis and Dr. Alan Rudolph envisioned and authored the Master Plan for the project. Dr. Nicolelis will now serve as a visiting professor of the Vita-Salute San Raffaele University and co-lead this Brazilian-Italian collaboration.</p><br/>

				<p>Developed originally for the demonstration of the first <a href="https://www.youtube.com/watch?v=ymyTEgi85Yg" target="_blank">brain-controlled lower limb exoskeleton</a>, which allowed a Brazilian paraplegic patient to deliver the opening kickoff of the 2014 FIFA Football World Cup, the neurorehabilitation protocols that will be implemented at the Center for Neurorehabilitation of the San Raffaele Neurotech Hub are all based on combining nBMIs, virtual reality, robotics and, more recently, non-invasive neuromodulatory techniques (<a href="https://www.nature.com/articles/srep30383" target="_blank">Donati et al, 2016</a>; <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0206464" target="_blank">Shokur et al, 2018</a>; <a href="https://www.nature.com/articles/s41598-019-43041-9" target="_blank">Selfslagh et 2019</a>; <a href="https://www.nature.com/articles/s41598-022-24864-5" target="_blank">Nicolelis et al 2022</a>).</p><br/>

				<p>According to Dr. Nicolelis “It is a dream come true to be able to establish this collaboration with one of the most prestigious hospitals in the world. This international partnership will allow patients suffering from a variety of devastating brain disorders to access state-of-the-art safe, affordable, and efficacious technologies based on BMIs. I am sure my good friend, John Chapin, who co-invented this technology with me, would be proud of this announcement. We hope to reach a large number of patients in the next few years and demonstrate categorically that non-invasive BMIs, combined with other modern technologies and data tools, will become a main approach to treat neurologic and psychiatric diseases in the near future.”</p><br/>

				<p>Professor Enrico Gherlone, Rector of the Vita-Salute San Raffaele University, comments: “We are very pleased to announce the start of the Neurotech Hub strategic project, which is the result of the partnership we have established with Professor Nicolelis and his team. Following the implementation of a two-year master plan, the Neurotech Hub is ready to enter the operational phase, ensuring that in the coming years we can pursue our strategic vision: neurotechnologies as a new and mandatory advanced medicine, with potential applications in improving both motor and cognitive health, as well as addressing neurological and psychiatric disorders. Undoubtedly, all of this represents a crucial opportunity, especially for our residents, providing them with the chance to engage with cutting-edge technology that is unique in Europe”.</p><br/>

				<p>Engineer Marco Centenari, Chief Executive Officer of IRCCS San Raffaele Hospital, comments: “The new Neurotech Hub represents one of the main strategic projects of our Institute. The collaboration with Professor Nicolelis is tangible proof of our commitment to supporting a long-term effort aimed at developing increasingly translational medicine. Nicolelis&#39; group will be supported by a team of excellent neurologists from our hospital led by Professor Massimo Filippi, who has internationally recognized expertise in testing new therapies for neuroinflammatory and neurodegenerative diseases, as well as in the development of new biomarkers to prevent and treat such diseases more effectively. We are confident that in the near future, we will be able to further advance a transdisciplinary field such as that which supports neurotechnological development, a valuable aid for healthier neuro-aging”.</p><br/>

				<p>In addition to a Center for Neurorehabilitation, the initial phase of the San Raffaele Neurotech Hub also contemplates the establishment of a Neurodata Laboratory and a new Neurotelemedicine Initiative. Moreover, a series of educational initiatives are also planned, including the creation of a comprehensive Neurorehabilitation Training Program for health care professionals, a global course on BMI, an International Undergraduate Fellow’s program and eventually a Graduate Program in Neurorehabilitation and BMI.</p><br/>

				<figure style="text-align: center;">
					<img src="https://img.aasdap.org.br/pages/niabs/picture1.jpeg" alt="ICM genérica não-invasiva" style="max-width: 100%; height: auto;">
					<figcaption>Figure 1 – The generic non-invasive BMI conceived by Dr. Nicolelis and his research team that will be used in the neurorehabilitation of multiple neurologic disorders at the San Raffaele Center for Neurorehabilitation of the Neurotech Hub.</figcaption>
				</figure><br/>

				<p>Regarding the establishment of such a comprehensive and unique international scientific and clinical partnership, Dr. Rudolph said: “We are excited to launch this program to help patients with neurological conditions and extend our work in innovative rehabilitation techniques that utilize new concepts and protocols to help those in need, and train the next generation of practitioners.”</p><br/>

				<p>The partnership with the San Raffaele Hospital and University is the first international initiative developed under the auspices of the Treat 1 Billion Project created by the Nicolelis Institute for Advanced Brain Studies. The central goal of this project is to deploy worldwide multiple neurotechnologies and therapies based on safe, efficient, and affordable non-invasive BMIs, in order to reach large numbers of patients suffering from devastating brain disorders. For that, the Nicolelis Institute intends to establish regional neurotech hubs on all continents.</p><br/>

				<h3>Additional information will be provided in the future.</h3><br/>
			`
		},
	];

	const content = contents.filter(el => el.language === languageProvider);

	useEffect(()=> {
		GApageView("instituto-nicolelis-de-estudos-avançados-do-cerebro")
	},[]);

	return (
		<Container>
			<Header />

			<Bg>
				<Section id="niabs">
					<ResponsiveContainer>
						<Content>
							<Logo>
								<img
									src="https://img.aasdap.org.br/pages/niabs/nicolelis_institute.jpeg"
									alt="Instituto Nicolelis"
								/>

								<img
									src="https://img.aasdap.org.br/pages/niabs/tob.jpeg"
									alt="Treat One Billion"
								/>
							</Logo>

							<p><i>{content[0]?.publication}</i></p>

							<Title>
								<h2>{content[0]?.title}</h2>
								<span className="dividerBlue"></span>
							</Title>

							{ReactHtmlParser(content[0]?.content)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Footer />
			</Bg>
		</Container>
	);
}

export default NIABS;
