import React, { useCallback, useEffect, useState, } from 'react';
import { Link } from "react-router-dom";

import { useLanguage } from '../../hooks/LanguageProvider';
import { GApageView } from '../../services/analytics';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

import { Container, TextHome } from './styles';

const Home: React.FC = () => {
	const [showModal, setShowModal] = useState(true);

	const { language } = useLanguage();

	const contents = [
		{language:"pt-BR", text:"A Associação Alberto Santos Dumont para Apoio à Pesquisa (AASDAP) é uma instituição privada sem fins lucrativos, criada em 2004 e certificada como Organização de Sociedade Civil de Interesse Público (OSCIP). Desde sua fundação atua na promoção do desenvolvimento humano e social através da implantação e gestão de projetos sociais e de pesquisas científicas."},
		{language:"en-US", text:"The Alberto Santos Dumont Association for Research Support (AASDAP) is a private non-profit institution, created in April 2004 and certified as a Civil Society Organization of Public Interest (OSCIP). It acts in fundraising and in the implementation and management of social projects and scientific research to promote human and social development."},
		{language:"cn", text:"亚伯托·桑托斯·杜蒙支持研究协会(AASDAP)是一个私人非营利机构. 成立于2004年，有公共利益公民社会组织(OSCIP)的认证。自从创立后便致力通过社会项目的开发和管理以及科学研究来促进人类以及社会的发展。"}
	];

	const titlePopup = [
		{
			language: "pt-BR",
			title: "Nova Parceria para Criação do Polo de Neurotecnologia San Raffaele em Milão",
			more: "saiba mais"
		},
		{
			language: "en-US",
			title: "New Partnership for the Creation of the San Raffaele Neurotechnology Hub in Milan",
			more: "learn more"
		}
	]

	const content = contents.filter(el => el.language === language);
	const popup = titlePopup.filter(el => el.language === language);

	const handleModal = useCallback(() => {
		const modalContainer = document.querySelector('.modalContainer');

		modalContainer?.classList.toggle('on', showModal);

		document.body.style.overflow = showModal ? 'hidden' : 'initial';

		setShowModal(!showModal);
	}, [showModal]);

	useEffect(()=> {
		GApageView("home");

		setTimeout(() => {
			handleModal();
		}, 1000);
	},[]);

	return (
		<>
			<Header />

			<Container>
				<TextHome>
				<p>{content[0]?.text || contents[1].text}</p>
				</TextHome>

				{language !== 'cn' && (
					<div className="modalContainer">
						<div className="modalUnit">
							<button className="closeModal" onClick={() => handleModal()}>X</button>

							<div className="contentUnit">
								<h2>{popup[0]?.title}<i>&nbsp;<Link to="/instituto-nicolelis-de-estudos-avançados-do-cerebro">{popup[0]?.more}</Link></i></h2>

								<figure style={{ textAlign: "center", marginTop: "30px" }}>
									<img src="https://img.aasdap.org.br/pages/niabs/picture1.jpeg" alt="ICM genérica não-invasiva" style={{ maxWidth: "100%", height: "auto" }} />
								</figure><br/>
							</div>
						</div>
					</div>
				)}
			</Container>

			<Footer />
		</>
	);
}

export default Home;
