import React from 'react';

import { Container } from './styles';

interface ResponsiveContainerProps {
  children: React.ReactNode;
}

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({children}) => {
  return(
    <Container>
      {children}
    </Container>
  );
}

export default ResponsiveContainer;
