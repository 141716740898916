import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #1E2B6E;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-align: center;
    font-size: 14px;
  }
`;
