import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RiMenuFill, RiCloseFill, RiArrowDownSFill, RiArrowRightSFill } from 'react-icons/ri';

import { useLanguage } from '../../hooks/LanguageProvider';

import ptBRFlag from '../../assets/pt-BR.png';
import enUSFlag from '../../assets/en-US.png';
import cnFlag from '../../assets/cn.png';

import {
	Container,
	TopBar,
	MainMenu,
	ContainerMainMenu,
	NavMenu
} from './styles';

import logoAasdap from '../../assets/aasdap-logo1.png';

const Header: React.FC = () => {
	const [ click, setClick ] = useState(false);
	const [ subInstitutional, setSubInstitutional] = useState(false);
	const [ subMedia, setSubMedia] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const {language, updateLanguage } = useLanguage();

	const menus = [
		{
			language:"pt-BR",
			home:"Home",
			institutional:"Institucional",
			about: "Sobre a AASDAP",
			history: "Nossa História",
			units:"Unidades",
			projects: "Projetos",
			wap: "Projeto Andar de Novo",
			scientificPublications: "Produção Científica",
			media: "Mídia",
			press: "Imprensa",
			news: "Notícias",
			contact: "Contato",
			niabs: "Instituto Nicolelis",
			niabsFull: "Instituto Nicolelis de Estudos Avançados do Cérebro"
		},
		{
			language:"en-US",
			home:"Home",
			institutional:"Institutional",
			about: "About AASDAP",
			history: "Our History",
			units:"Units",
			projects: "Projects",
			wap: "Walk Again Project",
			scientificPublications: "Scientific Publications",
			media: "Media",
			press: "Press",
			news: "News",
			contact: "Contact",
			niabs: "Nicolelis Institute",
			niabsFull: "Nicolelis Institute for Advanced Brain Studies"
		},
		{
			language:"cn",
			home:"首页",
			institutional:"公司",
			about: "关于 AASDAP",
			history: "故事",
			units:"Units",
			projects: "Projects",
			wap: "再度行走项目",
			scientificPublications: "科学生产",
			media: "影音",
			press: "影音",
			news: "新闻",
			contact: "接觸"
		},
	];

	const menuCurrent = menus.filter(el => el.language === language);

	const handleChange = useCallback((lang: string) => {
		sessionStorage.setItem("@aasdapLng", lang);
		updateLanguage(lang);
	},[updateLanguage]);

	const handleClick = useCallback(() => {
		document.body.style.overflow = !click ? "hidden" : "initial";

		setClick(!click)
	}, [click]);

	const handleInstitutional = useCallback(() => {
		setSubMedia(false)

		setSubInstitutional(!subInstitutional)
	}, [subInstitutional]);

	const handleMedia = useCallback(() => {
		setSubInstitutional(false);

		setSubMedia(!subMedia)
	}, [subMedia]);

	const closeMobileMenu = useCallback(() => {
		document.body.style.overflow = "initial";

		setClick(false)
	}, []);

	return (
		<Container>
			<TopBar>
				<div className="languages">
					<img src={ptBRFlag} alt="Portuguese" onClick={() => handleChange("pt-BR")} />
					<img src={enUSFlag} alt="English" onClick={() => handleChange("en-US")} />
					<img src={cnFlag} alt="Chinese" onClick={() => handleChange("cn")} />
				</div>
			</TopBar>

			<MainMenu>
				<ContainerMainMenu>
				<NavLink to="/">
					<img src={logoAasdap} alt="AASDAP"/>
				</NavLink>

				<div className="menu-icon" onClick={handleClick} >
					{
						click
						? <RiCloseFill size={30} />
						: <RiMenuFill size={30} />
					}
				</div>

				<NavMenu className={!click ? "nav-menu hidden" : "nav-menu" }>
					<li className="nav-item">
						<NavLink
							exact
							to="/"
							className="nav-link"
							activeClassName="active"
							onClick={closeMobileMenu}
						>
						{menuCurrent[0]?.home || menus[1].home}
						</NavLink>
					</li>

					<li className="submenu" onClick={handleInstitutional}>
						<div>
						{menuCurrent[0]?.institutional || menus[1].institutional}

						{
							!subInstitutional
							? <RiArrowDownSFill size={20} />
							: <RiArrowRightSFill size={20} />
						}
						</div>

						<ul className={!subInstitutional ? "hidden-submenu" : "show-submenu"}>
							<li className="nav-item nav-submenu-item">
								<NavLink
									to="/institucional/sobre-aasdap"
									className="nav-link"
									activeClassName="active"
									onClick={closeMobileMenu}
								>
									{menuCurrent[0]?.about || menus[1].about}
								</NavLink>
							</li>

							<li className="nav-item nav-submenu-item">
								<NavLink
									to="/institucional/historia"
									className="nav-link"
									activeClassName="active"
									onClick={closeMobileMenu}
								>
									{menuCurrent[0]?.history || menus[1].history}
								</NavLink>
							</li>

							{
								(language !== "cn") && (
								<li className="nav-item nav-submenu-item">
									<NavLink
									to="/institucional/unidades"
									className="nav-link"
									activeClassName="active"
									onClick={closeMobileMenu}
									>
									{menuCurrent[0]?.units || menus[1].units}
									</NavLink>
								</li>
								)
							}

							{
								(language !== "cn") && (
								<li className="nav-item nav-submenu-item">
									<NavLink
									to="/institucional/projetos"
									className="nav-link"
									activeClassName="active"
									onClick={closeMobileMenu}
									>
									{menuCurrent[0]?.projects || menus[1].projects}
									</NavLink>
								</li>
								)
							}
						</ul>
					</li>

					<li className="nav-item" >
						<NavLink to="/projeto-andar-de-novo" className="nav-link" onClick={closeMobileMenu}>
							{menuCurrent[0]?.wap || menus[1].wap}
						</NavLink>
					</li>

					{
						(language !== "cn") && (
							<li className="nav-item nav-submenu-item">
								<NavLink
									to="/instituto-nicolelis-de-estudos-avançados-do-cerebro"
									className="nav-link"
									onClick={closeMobileMenu}
									onMouseEnter={() => setIsHovered(true)}
									onMouseLeave={() => setIsHovered(false)}
								>
									{isHovered
										? (menuCurrent[0]?.niabsFull || menus[1].niabsFull)
										: (menuCurrent[0]?.niabs || menus[1].niabs)
									}
								</NavLink>
							</li>
						)
					}

					<li className="nav-item">
						<NavLink to="/producao-cientifica" className="nav-link" onClick={closeMobileMenu}>
						{menuCurrent[0]?.scientificPublications || menus[1].scientificPublications}
						</NavLink>
					</li>

					<li className="submenu" onClick={handleMedia}>
						<div>
						{menuCurrent[0]?.media || menus[1].media}

						{
							!subMedia
							? <RiArrowDownSFill size={20} />
							: <RiArrowRightSFill size={20} />
						}
						</div>

						<ul className={!subMedia ? "hidden-submenu" : "show-submenu"}>
						<li className="nav-item nav-submenu-item">
							<NavLink
							to="/midia/imprensa"
							className="nav-link"
							activeClassName="active"
							onClick={closeMobileMenu}
							>
							{menuCurrent[0]?.press || menus[1].press}
							</NavLink>
						</li>

						<li className="nav-item nav-submenu-item">
							<NavLink
							to="/midia/noticias"
							className="nav-link"
							activeClassName="active"
							onClick={closeMobileMenu}
							>
							{menuCurrent[0]?.news || menus[1].news}
							</NavLink>
						</li>


						</ul>
					</li>

					<li className="nav-item">
						<NavLink
						to="/contato"
						className="nav-link"
						activeClassName="active"
						onClick={closeMobileMenu}
						>
						{menuCurrent[0]?.contact || menus[1].contact}
						</NavLink>
					</li>
				</NavMenu>
				</ContainerMainMenu>
			</MainMenu>
		</Container>
	);
}

export default Header;
