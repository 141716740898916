import ReactGA from 'react-ga4';

export const initGA = () => {
	ReactGA.initialize(`${process.env.REACT_APP_ID_GOOGLE_ANALYTICS}`);
}

export const GApageView = (page: string) => {
	ReactGA.send({
		hitType: "pageview",
		page
	});
}
