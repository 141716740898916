import { createGlobalStyle } from 'styled-components';

// #f09f30
// #1E2B6E
// #5A892F

export default createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}

	body {
		background: #F0F0F5;
		height: 100%;
		color: #000;
		-webkit-font-smoothing: antialiased;
		overflow-x: hidden;
	}

	body, input, button, textarea, select {
		font-family: 'Helvetica', 'Arial', sans-serif;
		font-size: 16px;
		line-height: 20px;
	}

	h1 {
		font-size: 30px;
		line-height: 40px;
	}

	h2 {
		font-size: 28px;
		line-height: 38px;
	}

	h3 {
		font-size: 25px;
		line-height: 35px;
	}

	h4 {
		font-size: 18px;
		line-height: 22px;
	}

	p {
		font-size: 20px;
		line-height: 33px;
	}

	a {
		text-decoration: none;
		color: #f09f30;
		font-weight: bold;

		&:hover {
			color: #1E2B6E;
		}
	}

	button {
		cursor: pointer;
	}

	.responsiveContainer {
		width: 100%;
		max-width: 950px;
	}

	@media screen and (max-width: 576px) {
		h1 {
			font-size: 26px;
			line-height: 30px;
		}

		h2 {
			font-size: 24px;
			line-height: 29px;
		}

		h3 {
			font-size: 18px;
			line-height: 27px;
		}

		p, a {
			font-size: 18px;
			line-height: 27px;
		}
	}


`;
