import styled from 'styled-components';

import background from '../../assets/bg_units_projects.png';

export const Container = styled.div`
	width: 100vw;
	overflow-x: hidden;
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background: url(${background});
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const CardsContainer = styled.section`
	margin: 30px 0;

	display: flex;
	flex-direction: column;
	align-items: center;

	.pagination {
		font-size: 18px;
		list-style: none;
		text-decoration: none;
		padding-top: 20px;

		display: flex;
		align-items: center;
		justify-content: center;

		li + li {
				margin-left: 15px;
		}
	}
`;

export const Cards = styled.div`
	width: 100%;
	max-width: 1920px;
	padding: 15px;
	margin: 0 auto 20px;
	background-color: #ffffff8f;

	border-radius: 36px;
	border-left: 5px solid #F09F30;
	border-right: 5px solid #F09F30;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);

	display: flex;
	align-items: stretch;
	justify-content: flex-start;

	@media (max-width: 624px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.cover {
		padding: 10px auto;
		height: 100%;

		display: flex;
		flex-direction: column;
		flex-direction: center;
		justify-content: center;

		img {
			width: 200px;
			height: 180px;
			border-radius: 14px;
		}
	}

	.containerMobile {
		width: 100%;
		display: flex;

		@media (max-width: 853px) {
			flex-direction: column;
		}

		@media (max-width: 624px) {
			align-items: center;
		}
	}

	.textCard {
		padding: 0 15px 0 20px;
		flex: 1;

		display: flex;
		flex-direction: column;

		@media (max-width: 414px) {
			padding: 0px;
		}
	}

	h2 {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 8px;

		@media (max-width: 364px) {
			align-items: center;
		}
	}

	span {
		font-size: 16px;
		line-height: 20px;
	}

	.linkArticle {
		width: 120px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media (max-width: 853px) {
			width: 225px;
			flex-direction: row;
		}

		a {
			text-decoration: none;
			color: #fff;
			padding: 10px;

			& + a {
				margin-top: 12px;

				@media (max-width: 853px) {
					margin-top: 0;
				}
			}

			.linksButtom {
				background: #F09F30;
				border-radius: 8px;
				width: 80px;
				height: 70px;
				border: 1px solid #ccc;
				box-shadow: 4px 4px 4px #ccc;
				padding: 5px auto;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&:hover {
					position: relative;
					top: -10px;
				}

				span {
					margin-top: 5px;
					font-weight: bold;
				}
			}
		}

	}
`;



