import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	padding: 13px;
	color: #000;
	background: #d8dcee26;
	border: 2px solid #1e2b6e;
	border-radius: 18px;

	display: flex;
	align-items: center;

	& + div {
		margin-top: 11px;
	}

	textarea {
		flex: 1;
		min-height: 120px;
		background: transparent;
		border: 0;
		color: #000;
	}

	textarea::placeholder {
		color: #000000c9;
	}
`;
