import styled from 'styled-components';

import background from '../../assets/bg_units_projects.png';

export const Container = styled.main`
	width: 100vw;
	max-width: 1920px;
	display: flex;

	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background: url(${background});
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const Cards = styled.section`
	margin: 0 auto 30px;
	min-height: calc(100vh - 330px);

	.pagination {
		font-size: 18px;
		list-style: none;
		text-decoration: none;
		padding-top: 20px;

		display: flex;
		align-items: center;
		justify-content: center;

		li + li {
			margin-left: 15px;
		}
	}

	a {
		text-decoration: none;
	}

	.cardsContainer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
`;

