import styled from 'styled-components';

import background from '../../assets/bg_units_projects.png';

export const Container = styled.div`
	width: 100vw;
	overflow-x: hidden;
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background: url(${background});
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const CardsContainer = styled.section`
	margin: 30px 0;

	display: flex;
	flex-direction: column;
	align-items: center;

	.pagination {
		font-size: 18px;
		list-style: none;
		text-decoration: none;
		padding-top: 20px;

		display: flex;
		align-items: center;
		justify-content: center;

		li + li {
			margin-left: 15px;
		}
	}

	a {
		text-decoration: none;
	}

	.cardsContainer {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
	}
`;

export const Cards = styled.div`
	width: 100%;
	max-width: 1920px;
	padding: 25px;
	margin: 0 auto 20px;
	background-color: #ffffff8f;
	color: #000;

	border-radius: 36px;
	border-left: 5px solid #F09F30;
	border-right: 5px solid #F09F30;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	@media (max-width: 446px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&:hover {
		position: relative;
		top: -10px;
	}

	.cover {
		padding: 10px auto;
		height: 100%;

		display: flex;
		flex-direction: column;
		flex-direction: center;
		justify-content: center;

		img {
			width: 200px;
			height: 180px;
			border-radius: 10px;

			@media (max-width: 554px) {
				width: 150px;
				height: 135px;
			}
		}
	}

	.textCard {
		padding: 0 15px 0 20px;

		display: flex;
		flex-direction: column;
	}

	h2 {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 8px;

		@media (max-width: 446px) {
			margin-top: 15px;
		}
	}

	span {
		font-size: 15px;

		display: flex;
		align-items: center;

		svg {
			margin-right: 10px;
		}

		& + span {
			margin-top: 8px;
		}
	}
`;



