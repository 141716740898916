import styled from 'styled-components';
import { shade } from 'polished';

import bgContact from '../../assets/bg_about_aasdap.png';

export const Container = styled.div`
	width: 100%;

	background-color: #F0F0F5;
	background: url(${bgContact}) no-repeat;
	background-position: center top;
	background-size: 100% auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media only screen and (min-width: 1450px) {
		background-position: center -40px;
		background-size: 1450px auto;
	}

	@media only screen and (max-width: 1000px) {
		background-size: cover;
	}

	@media only screen and (max-width: 700px) {
		background-position: center -65px;
	}

	@media only screen and (max-width: 630px) {
		background-position: center -80px;
	}
`;

export const ContainerContent = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ContainerContact = styled.div`
	width: 800px;
	margin: 100px 0 60px 80px;
	display: flex;

	min-height: calc(100vh - 330px);

	.form {
		flex: 1;
		width: 400px;
		padding: 30px 20px 30px 0;

		button {
			width: 223px;
			height: 54px;
			margin: 20px auto 0;
			font-weight: bold;

			background: #F09F30;
			color: #fff;
			box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
			border: none;
			border-radius: 53px;

			display: block;

			&:hover {
				background: ${shade(0.2, '#F09F30')};
			}
		}
	}

	@media only screen and (max-width: 900px) {
		width: 700px;
	}

	@media only screen and (max-width: 800px) {
		width: 600px;
	}

	@media only screen and (max-width: 700px) {
		width: 97%;
		margin-top: 40px;
		margin-left: 0px;

		flex-direction: column;
		justify-content: center;
		align-items: center;

		.form {
			width: 90%;
			padding: 0px 10px 30px 10px;
			margin-top: -90px;
		}
	}
`;

export const ContactInfo = styled.div`
	width: 280px;

	.containerContactInfo {
		width: 300px;
		height: 380px;

		margin-top: 55px;

		position: relative;
		left: -40px;
	}

	.contactInfo {
		height: 235px;
		padding-top: 50px;

		display: flex;
		flex-direction: column;
		align-items: center;

		h1 {
			margin-bottom: 20px;

			@media only screen and (max-width: 630px) {
				margin-bottom: 0px;
			}
		}

		.mailContact {
			margin-bottom: 20px;
			font-size: 20px;

			display: flex;
			align-items: center;

			span {
				margin-left: 10px;
			}
		}

		@media only screen and (max-width: 630px) {
			height: auto;
		}
	}

	@media (max-width: 630px) {
		width: 70%;

		.containerContactInfo{
			width: 100%;
			height: 270px;
			left: 0;
			top: -50px;
		}

		.contactInfo {
			margin: 0;

			h1 {
				text-align: center;
			}
		}
	}

	@media (max-width: 450px) {
		width: 90%;
	}
`

export const TitlePage = styled.div`
	width: 100%;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		padding: 0px 40px 10px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const Spinner = styled.span`
	position: relative;
	color: #fff;

	@keyframes spinner {
		to {transform: rotate(360deg);}
	}

	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 50%;
		border: 2px solid #ccc;
		border-top-color: #000;
		animation: spinner .6s linear infinite;
	}
`;

