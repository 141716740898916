import styled from 'styled-components';

import bgOurHistory from '../../assets/bg_our_history.png';

export const Main = styled.main`
	width: 100%;
	background-color: #F0F0F5;
	background: url(${bgOurHistory});
	background-repeat: repeat-y;
	background-position: center top;
	background-size: cover;

	@media only screen and (min-width: 992px) {
		background-size: 100% 100%;
	}

	@media only screen and (min-width: 1200px) {
		background-size: 100% auto;
	}

	@media only screen and (min-width: 1491px) {
		background-size: 1491px 100%;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const OurHistory = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const OurHistoryContent = styled.div`
	width: 100%;
	max-width: 1511px;
	margin: auto auto 30px;

	h2 {
		text-align: center;
		font-size: 34px;
		text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
	}

	hr {
		margin: 20px 0 30px;
		border-color: #ccc;
	}

	p, span {
		font-size: 20px;
		line-height: 32px;
		text-align: justify;
	}
`;

export const DreamWeRealizeTogether = styled.section`
	width: 100%;
	margin-left: auto;

	display: flex;
	place-content: center;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const DreamWeRealizeTogetherContent = styled.div`
	width: 100%;
	max-width: 1511px;
	margin: 30px auto;

	.rightText {
		width: 80%;
		margin-left: auto;

		@media (max-width: 900px) {
			width: 90%;
		}

		@media (max-width: 800px) {
			width: 100%;
		}
	}

	.rightTitle {
		width: 70%;
		margin-left: auto;

		@media (max-width: 1157px) {
			width: 80%;
		}

		@media (max-width: 1013px) {
			width: 90%;
		}

		@media (max-width: 720px) {
			width: 100%;
		}
	}

	p, span {
		font-size: 20px;
		color: #000;
		line-height: 32px;
		text-align: right;

		@media (max-width: 768px) {
			text-align: justify;
		}
	}

	a {
		text-decoration: none;
		color: #000;
		font-weight: bold;
	}

	a:hover {
		color: #1E2B6E;
	}
`;

export const TheWalkContinues = styled.section`
	display: flex;
	place-content: center;
`;

export const TheWalkContinuesContent = styled.div`
	width: 100%;
	max-width: 1511px;
	margin: 30px auto 100px;

	.leftText {
		width: 90%;
		margin-right: auto;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.leftText .leftTitle {
		width: 70%;
		margin-right: auto;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	p, span {
		font-size: 20px;
		line-height: 32px;
		text-align: left;

		@media (max-width: 768px) {
			text-align: justify;
		}
	}
`;
