import React, {useCallback, useState, useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useLanguage } from '../../hooks/LanguageProvider';
import { GApageView } from '../../services/analytics';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import LoadingPage from '../../components/LoadingPage';

import {
	Title,
	Main,
	OurHistory,
	OurHistoryContent,
	DreamWeRealizeTogether,
	DreamWeRealizeTogetherContent,
	TheWalkContinues,
	TheWalkContinuesContent
} from './styles';

interface IContentsProps {
	id: number;
	about_us_ref_id: number;
	title?: string;
	description: string;
}

const History: React.FC = () => {
	const [contentsPage, setContentsPage] = useState<IContentsProps[]>([]);
	const [loadingPage, setLoadingPage] = useState(true);
	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"História"},
		{language:"en-US", title:"History"},
		{language:"cn", title:"故事"}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);

	const historySection = contentsPage.filter(el => el.about_us_ref_id === 4);
	const dreamsSection = contentsPage.filter(el => el.about_us_ref_id === 5);
	const walkSection = contentsPage.filter(el => el.about_us_ref_id === 6);

	const getData = useCallback(() => {
		let languagePage = languageProvider;

		api.get(
			`/about-us/lang/${languagePage}`
			).then((response) => {
			setContentsPage(response.data);
			setLoadingPage(false);
		});
	}, [languageProvider]);

	useEffect(()=> {
		getData()
		GApageView("institucional/historia")
	},[getData]);

	if (loadingPage) {
		return <LoadingPage />;
	}

	return (
		<>
			<Header />

			<Main>
				<Title>
					<h1>{title[0]?.title}</h1>
					<span className="dividerBlue"></span>
				</Title>

				<OurHistory>
					<ResponsiveContainer>
						<OurHistoryContent>
							{ReactHtmlParser(historySection[0]?.description)}
						</OurHistoryContent>
					</ResponsiveContainer>
				</OurHistory>

				<DreamWeRealizeTogether>
					<ResponsiveContainer>
						<DreamWeRealizeTogetherContent>
							<div className="rightText">
								<Title className="rightTitle">
									<h2>{dreamsSection[0]?.title}</h2>
									<span className="dividerGreen"></span>
								</Title>

								{ReactHtmlParser(dreamsSection[0]?.description)}
							</div>
						</DreamWeRealizeTogetherContent>
					</ResponsiveContainer>
				</DreamWeRealizeTogether>

				<TheWalkContinues>
					<ResponsiveContainer>
						<TheWalkContinuesContent>
							<div className="leftText">
								<Title className="leftTitle">
									<h2>{walkSection[0]?.title}</h2>
									<span className="dividerOrange"></span>
								</Title>

								{ReactHtmlParser(walkSection[0]?.description)}
							</div>
						</TheWalkContinuesContent>
					</ResponsiveContainer>
				</TheWalkContinues>
			</Main>

			<Footer />
		</>
	);
}

export default History;
