import styled from 'styled-components';

import bgAboutAASDAP from '../../assets/bg_about_aasdap.png';

export const Container = styled.div`
	background-color: #F0F0F5;
	background: url(${bgAboutAASDAP}) no-repeat;
	background-position: center top;
	background-size: 100% auto;

	@media only screen and (min-width: 1450px) {
		background-position: center -40px;
		background-size: 1450px auto;
	}

	@media only screen and (max-width: 1000px) {
		background-size: cover;
	}
`;

export const Main = styled.main`
	min-height: calc(100vh - 297px);
`;

export const AboutUsContainer = styled.section`
	display: flex;
	place-content: center;
`;

export const AboutUsContent = styled.div`
	width: 100%;
	max-width: 1280px;
	padding: 0px 0px 50px;

	display: flex;
	flex-direction: column;
	margin: 0px auto;
	justify-content: center;

	p, span {
		font-size: 20px;
		line-height: 32px;
		text-align: center;
	}

	@media (max-width: 640px) {
		height: auto;
		padding: 30px 0;
	}
`;

export const TitlePage = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}
`;

export const MissionVision = styled.section`
	width: 100%;
	max-width: 1280px;
	margin: auto auto 50px;

	display: flex;
	flex-direction: column;
	align-items: center;

	.containerMissionVision {
		padding: 20px 20px;
		border: 3px solid #1E2B6E;
		border-radius: 72px;

		display: flex;
		justify-content: space-around;

		@media (max-width: 768px) {
			flex-direction: column;
		}
	}

	.logoMissionVision {
		width: 440px;

		@media (max-width: 1050px) {
			width: 470px;
		}

		@media (max-width: 768px) {
			width: 100%;
			margin-bottom: 30px;

			/* display: none; */
		}

		display: flex;
		align-items: center;
		justify-content: center;

		.circleIcon {
			width: 180px;
			height: 180px;
			background: rgb(0,61,135);
			background: radial-gradient(
				circle,
				rgba(0,61,135,1) 91%,
				rgba(0,31,68,1) 100%
			);

			border-radius: 50%;

			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 80%;
			}

			@media (max-width: 768px) {
				width: 130px;
				height: 130px;
			}
		}
	}

	.infoMissionVision {
		padding: 15px 20px;
	}

	.block-item {
		display: flex;
		flex-direction: column;

		& + div {
			margin-top: 40px;
		}

		.block-text {
			display: flex;
			flex-direction: column;

			@media (max-width: 768px) {
				align-items: center;
			}
		}

		.block-title {
			font-size: 28px;
			padding-bottom: 10px;
			font-weight: 600;
			color: #1E2B6E;

			margin-right: 10px;
		}

		span {
			font-size: 20px;
			line-height: 26px;
			color: #000000ba;

			@media (max-width: 768px) {
				text-align: center;
			}
		}
	}
`;
