import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  /* width: 100%; */
  /* max-width: 1000px; */

  @media (max-width: 1050px){
    /* width: 90%; */
    /* width: 90%; */
    /* max-width: 1000px; */
  }
`;
