import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GApageView } from '../../services/analytics';

import LoadingPage from '../../components/LoadingPage';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import { Container } from './styles';

interface INewsContentProp {
  id?: string;
}
interface INewsProp {
  id: number;
  name: string;
  description: string;
  publication_date: Date;
}

const NewsContent: React.FC<INewsContentProp> = ({
  id
}) => {
  const [newsContent, setNewsContent] = useState<INewsProp>();

  const {language: languageProvider } = useLanguage();

  useEffect(()=>{
    let languagePage = languageProvider === "pt-BR" ? "pt-BR" : "en-US";

    GApageView(`noticias/${id}`);

    api.get<INewsProp[]>(`/news/lang/${languagePage}/${id}`).then((response) => {
      response.data[0] && setNewsContent({
        id: response.data[0].id,
        name: response.data[0].name,
        description: response.data[0].description,
        publication_date: response.data[0].publication_date,
      });
    })
  },[id, languageProvider])

  return (
    <>
      {
        !newsContent
        ? <LoadingPage />
        : (
            <Container>
              <h2>{newsContent.name}</h2>

              {ReactHtmlParser(newsContent.description)}
            </Container>
          )
      }
    </>
  );
}

export default NewsContent;
