import React, {useCallback, useState, useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useLanguage } from '../../hooks/LanguageProvider';
import { GApageView } from '../../services/analytics';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import LoadingPage from '../../components/LoadingPage';

import Icon14Bis from '../../assets/14-bis.png';

import {
	Container,
	TitlePage,
	Main,
	AboutUsContainer,
	AboutUsContent,
	MissionVision,
} from './styles';

interface IContentsProps {
	id: number;
	about_us_ref_id: number;
	title?: string;
	description: string;
}

const AboutUs: React.FC = () => {
	const [contentsPage, setContentsPage] = useState<IContentsProps[]>([]);
	const [loadingPage, setLoadingPage] = useState(true);
	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Sobre a AASDAP"},
		{language:"en-US", title:"About AASDAP"},
		{language:"cn", title:"关于亚伯托·桑托斯·杜蒙研究支持协会"}
	];

	const title = titlesPage.filter(el => el.language === languageProvider);

	const aboutUsSection = contentsPage.filter(el => el.about_us_ref_id === 1);
	const missionSection = contentsPage.filter(el => el.about_us_ref_id === 2);
	const visionSection = contentsPage.filter(el => el.about_us_ref_id === 3);

	const getData = useCallback(() => {
		let languagePage = languageProvider;

		api.get(
			`/about-us/lang/${languagePage}`
			).then((response) => {
			setContentsPage(response.data);
			setLoadingPage(false);
		});
	}, [languageProvider]);

	useEffect(()=> {
		getData()
		GApageView("institucional/sobre-aasdap")
	},[getData]);

	if(loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<TitlePage>
				<h2>{title[0]?.title}</h2>
				<span className="dividerBlue"></span>
			</TitlePage>

			<Main>
				<AboutUsContainer>
					<ResponsiveContainer>
						<AboutUsContent>
							{ReactHtmlParser(aboutUsSection[0]?.description)}
						</AboutUsContent>
					</ResponsiveContainer>
				</AboutUsContainer>

				<MissionVision>
					<ResponsiveContainer>
						<div className="containerMissionVision">
							<div className="logoMissionVision">
								<div className="circleIcon">
									<img src={Icon14Bis} alt="14 Bis" />
								</div>
							</div>

							<div className="infoMissionVision">
								<div className="block-item">
									<div className="block-text">
										<span className="block-title">{missionSection[0]?.title}</span>
										{ReactHtmlParser(missionSection[0]?.description)}
									</div>
								</div>

								<div className="block-item">
									<div className="block-text">
										<span className="block-title">{visionSection[0]?.title}</span>
										<span>{ReactHtmlParser(visionSection[0]?.description)}</span>
									</div>
								</div>
							</div>
						</div>
					</ResponsiveContainer>
				</MissionVision>
			</Main>

			<Footer />
		</Container>
	);
}

export default AboutUs;
