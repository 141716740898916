import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';

interface LanguageProviderProps {
	children: React.ReactNode;
}

interface LanguageContextData {
	language: string;
	updateLanguage(lang: string): void;
}

const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData);

const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
	const[language, setLanguage] = useState<string>("");

	const defaultLanguage = "pt-BR";

	const currentLanguage = sessionStorage.getItem('@aasdapLng') || defaultLanguage;

	const verifyLanguage = useCallback((lang: string) => {
		switch(lang) {
			case "en":
			case "en-US":
				return "en-US";

			case "pt":
			case "pt-BR":
			case "pt-PT":
				return "pt-BR";

			case "cn":
			case "zh":
			case "zh-CN":
			case "zh-HK":
			case "zh-TW":
				return "cn";

		default:
			return "pt-BR";
		}
	},[])

	const updateLanguage = useCallback((lang: string) => {
		const languangeUpdated = verifyLanguage(lang);

		sessionStorage.setItem('@aasdapLng', languangeUpdated)

		setLanguage(lang);
	},[verifyLanguage]);

	useEffect(() => {
		updateLanguage(currentLanguage)
	},[updateLanguage, currentLanguage])

	return (
		<LanguageContext.Provider value={{language, updateLanguage}}>
			{children}
		</LanguageContext.Provider>
	);
}

export function useLanguage() {
	const context = useContext(LanguageContext);

	if (!context) throw new Error("useLanguage must be used within a LanguageProvider");

	const { language, updateLanguage } = context;

	return { language, updateLanguage };
}

export default LanguageProvider;
