import styled from 'styled-components';

export const Container = styled.div`
	background-color: #fff;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

	position: sticky;
	top: -39px;
	z-index: 9;
`;

export const TopBar = styled.div`
	width: 100%;
	height: 38px;
	background-color: #1E2B6E;
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	.languages {
		width: 125px;
		margin-right: 50px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		img {
			border-radius: 4px;

			&:hover {
				cursor: pointer;
			}
		}
	}
`;

export const MainMenu = styled.div`
	width: 100vw;
	height: 90px;

	display: flex;
	align-items: center;
	justify-content: center;

	.nav-link {
		font-weight: 500;
	}

	@media (max-width: 600px) {
		height: 78px;
	}
`;

export const ContainerMainMenu = styled.div`
	width: 90%;
	height: 100%;
	max-width: 1920px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	img {
		width: 160px;
	}

	a {
		color: #1E2B6E;
		text-decoration: none;
		margin-left: 20px;
		font-size: 16px;

		&:hover {
			color: #fff;
		}
	}

	.nav-menu {
		right: 0px;
	}

	.nav-menu.hidden {
		@media screen and (max-width: 869px) {
			display: none;
		}
	}

	@media screen and (min-width: 870px) {
		width: 98%;

		img {
			width: 200px;
		}

		.menu-icon {
			display: none;
		}

		.nav-menu.hidden {
			right: 0px;
		}
	}
`;

export const NavMenu = styled.ul`
	width: 300px;
	background: #fff;
	color: #1E2B6E;
	list-style: none;

	position: absolute;
	top: 117px;
	right: 0px;
	transition: all 0.5s;

	.nav-item {
		width: 100%;
		height: 60px;
		cursor: pointer;

		& + li {
			border-top: 1px solid #1E2B6E;
		}

		&:hover {
			background: #1E2B6E;
		}

		a {
			height: 100%;
			margin-left: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		li ul {
			display: block;
			padding-left: 20px;
		}
	}

	.active {
		color: #FF7F00;
		font-weight: bold;
	}

	li.submenu {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		div {
			width: 100%;
			height: 60px;
			border-bottom: 1px solid #1E2B6E;

			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background: #1E2B6E;
				color: #fff;
			}
		}

		li {
			list-style: none;
			background-color: #f0f0f0;
		}
	}

	.nav-submenu-item {
		padding-left: 0;
	}

	.show-submenu {
		display: block;
		width: 100%;
		border-bottom: 1px solid #1E2B6E;
	}

	.hidden-submenu {
		display: none;
	}

	@media screen and (min-width: 870px) {
		width: auto;

		position: relative;
		top: 0;
		right: 0;

		display: flex;

		&:hover {
			background-color: #fff;
		}

		& li a {
			font-size: 13px;
		}

		.show-submenu {
			display: none;
			border-bottom: 0;
		}

		.menu-icon2 {
			display: none;
		}

		.nav-item {
			width: auto;
			border: none;
			margin-right: 15px;

			& + li {
				border: none;
			}

			&:hover {
				background-color: #fff;

				a {
					color: #FF7F00;
				}
			}
		}

		li.submenu {
			margin-right: 15px;

			&:hover ul {
				width: 200px;

				display: block;
				margin-right: -17px;

				position: absolute;
				top: 55px;
			}

			div {
				border: none;
				font-size: 16px;

				&:hover {
				background-color: #fff;
				color: #FF7F00;
				}
			}

			& ul li {
				border: 1px solid #ccc;
			}
		}
	}

	@media screen and (min-width: 878px) {
		& li a {
			font-size: 14px;
		}

		li.submenu div {
			font-size: 14px;
		}
	}

	@media screen and (min-width: 900px) {
		& li a {
			font-size: 16px;
		}

		li.submenu div {
			font-size: 16px;
		}
	}
`;
