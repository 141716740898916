import React, { useState, useCallback, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GApageView } from '../../services/analytics';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import LoadingPage from '../../components/LoadingPage';

import {
	Container,
	LogoWap,
	Bg,
	Title,
	Section,
	VideoWapContent,
	Content,
	ScientificPosters
} from './styles';
interface IContentsProps {
	id: number;
	wap_ref_id: number;
	title?: string;
	description: string;
}

const WAP: React.FC = () => {
	const [showPosters, setShowPosters] = useState<boolean>(false);
	const [contentsPage, setContentsPage] = useState<IContentsProps[]>([]);
	const [loadingPage, setLoadingPage] = useState(true);
	const {language: languageProvider } = useLanguage();

	const scientificProductionTitles = [
		{language:"pt-BR", title:"Produção Científica"},
		{language:"en-US", title:"Scientific Production"},
		{language:"cn", title:"科学生产"}
	];

	const scientificMeetingsTitles = [
		{language:"pt-BR", title:"Pôsteres em Eventos Científicos"},
		{language:"en-US", title:"Poster in scientific meetings"},
		{language:"cn", title:"科学活动的海报"}
	];

	const scientificProductionTitle = scientificProductionTitles.filter(
		el => el.language === languageProvider
	);

	const scientificMeetingsTitle = scientificMeetingsTitles.filter(
		el => el.language === languageProvider
	);

	const showMoreTranslations = [
		{language:"pt-BR", title:"Mostrar Mais"},
		{language:"en-US", title:"Show More"},
		{language:"cn", title:"展示更多"}
	];

	const showLessTranslations = [
		{language:"pt-BR", title:"Mostrar Menos"},
		{language:"en-US", title:"Show Less"},
		{language:"cn", title:"顯示較少"}
	];

	const showMore = showMoreTranslations.filter(
		el => el.language === languageProvider
	);

	const showLess = showLessTranslations.filter(
		el => el.language === languageProvider
	);

	const projectSection = contentsPage.filter(el => el.wap_ref_id === 1);
	const exoskeletonSection = contentsPage.filter(el => el.wap_ref_id === 2);
	const neuroImprovementSection = contentsPage.filter(el => el.wap_ref_id === 3);
	const fesSection = contentsPage.filter(el => el.wap_ref_id === 8);
	const partnershipsSection = contentsPage.filter(el => el.wap_ref_id === 4);
	const financialSupportSection = contentsPage.filter(el => el.wap_ref_id === 5);
	const articlesJournalsSection = contentsPage.filter(el => el.wap_ref_id === 6);
	const conferenceArticlesSection = contentsPage.filter(el => el.wap_ref_id === 7);

	const handlePosters = useCallback(() => {
		let extraPosters = document.querySelector(".hiddenPosters");

		extraPosters?.classList.toggle("showPosters", !showPosters);

		setShowPosters(!showPosters)
	},[showPosters]);

	const getData = useCallback(() => {
		let languagePage = languageProvider;

		if (languagePage) {
			api.get(`/wap/lang/${languagePage}`).then((response) => {
				setContentsPage(response.data);
				setLoadingPage(false);
			});
		}
	}, [languageProvider]);

	useEffect(()=> {
		getData()
		GApageView("projeto-andar-de-novo")
	},[getData]);

	if (loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<Bg>
				<Section id="presentationVideo">
					<ResponsiveContainer>
						<LogoWap>
							<img
								src={`${process.env.REACT_APP_URL_IMG}/pages/wap/wap-logo.png`}
								alt="AASDAP"
							/>
						</LogoWap>

						<VideoWapContent>
							<div>
								<iframe
									title="Celebrating the 5th anniversary of the opening kickoff of the World Cup in Brazil"
									src="https://www.youtube.com/embed/ymyTEgi85Yg"
								/>
							</div>
						</VideoWapContent>
					</ResponsiveContainer>
				</Section>

				<Section id="icmaqWap">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{projectSection[0]?.title}</h2>
								<span className="dividerBlue"></span>
							</Title>

							{ReactHtmlParser(projectSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="exoskeleton">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{exoskeletonSection[0]?.title}</h2>
								<span className="dividerOrange"></span>
							</Title>

							{ReactHtmlParser(exoskeletonSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="neuroImprovement">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{neuroImprovementSection[0]?.title}</h2>
								<span className="dividerGreen"></span>
							</Title>

							{ReactHtmlParser(neuroImprovementSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="fes">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{fesSection[0]?.title}</h2>
								<span className="dividerBlue"></span>
							</Title>

							{ReactHtmlParser(fesSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="partnerships">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{partnershipsSection[0]?.title}</h2>
								<span className="dividerGreen"></span>
							</Title>

							{ReactHtmlParser(partnershipsSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="financial">
					<ResponsiveContainer>
						<Content>
							<Title>
								<h2>{financialSupportSection[0]?.title}</h2>
								<span className="dividerBlue"></span>
							</Title>

							{ReactHtmlParser(financialSupportSection[0]?.description)}
						</Content>
					</ResponsiveContainer>
				</Section>

				<Section id="scientificProduction">
					<ResponsiveContainer>
						<Content>
							<Title className="clearBottom">
								<h2>{scientificProductionTitle[0]?.title}</h2>
								<span className="dividerOrange"></span>
							</Title>

							<ScientificPosters>
								<h3>{articlesJournalsSection[0]?.title}</h3>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1038/s41598-019-43041-9"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<h4>
												Non-invasive, Brain-controlled functional electrical stimulation for locomotion rehabilitation in paraplegic patients
											</h4>

											<div className="authors">
												<strong>Authors</strong>: Aurelie Selfslagh, Solaiman Shokur*, Debora S. F. Campos, Ana R. C. Donati, Sabrina Almeida, Seidi Y. Yamauti, Daniel B. Coelho, Mohamed Bouri & Miguel A. L. Nicolelis
											</div>

											<div className="journal">
												<strong>Scientific Journal</strong>: Scientific Reports volume 9, Article number: 6782
											</div>

											<div className="published">
												<strong>Publicação</strong>: 01 May 2019
											</div>

											<div className="doi">
												<strong>DOI</strong>: https://doi.org/10.1038/s41598-019-43041-9
											</div>
											<div className="borderBottomArticle"></div>
										</div>
									</a>
								</div>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1371/journal.pone.0206464"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<div className="borderTopArticle"></div>

											<h4>
												Training with brain-machine interfaces, visuo-tactile feedback and assisted locomotion improves sensorimotor, visceral, and psychological signs in chronic paraplegic patients
											</h4>

											<div className="authors">
												<strong>Authors</strong>: Solaiman Shokur, Ana R.C. Donati, Debora S.F. Campos, Claudia Gitti, Guillaume Bao, Dora Fischer, Sabrina Almeida, Vania A.S. Braga, Patricia Augusto, Chris Petty, Eduardo J.L. Alho, Mikhail Lebedev, Allen W. Song, Miguel A.L. Nicolelis
											</div>

											<div className="journal">
												<strong>Scientific Journal</strong>: PLOS ONE 13(11):e0206464
											</div>

											<div className="published">
												<strong>Publicação</strong>: 29 Nov 2018
											</div>

											<div className="doi">
												<strong>DOI</strong>: https://doi.org/10.1371/journal.pone.0206464
											</div>

											<div className="borderBottomArticle"></div>
										</div>
									</a>
								</div>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1038/srep32293"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<div className="borderTopArticle"></div>

											<h4>
												Assimilation of virtual legs and perception of floor texture by complete paraplegic patients receiving artificial tactile feedback
											</h4>

											<div className="authors">
												<strong>Authors</strong>: Solaiman Shokur, Simone Gallo, Renan C. Moioli, Ana Rita C. Donati, Edgard Morya, Hannes Bleuler & Miguel A.L. Nicolelis
											</div>

											<div className="journal">
												<strong>Scientific Journal</strong>: Scientific Reports volume 6, Article number: 32293
											</div>

											<div className="published">
												<strong>Publicação</strong>: 19 Sep 2016
											</div>

											<div className="doi">
												<strong>DOI</strong>: https://doi.org/10.1038/srep32293
											</div>

											<div className="borderBottomArticle"></div>
										</div>
									</a>
								</div>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1038/srep30383"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<div className="borderTopArticle"></div>

											<h4>
													Long-Term Training with a Brain-Machine Interface-Based Gait Protocol Induces Partial Neurological Recovery in Paraplegic Patients
											</h4>

											<div className="authors">
												<strong>Authors</strong>: Ana R. C. Donati, Solaiman Shokur, Edgard Morya, Debora S. F. Campos, Renan C. Moioli, Claudia M. Gitti, Patricia B. Augusto, Sandra Tripodi, Cristhiane G. Pires, Gislaine A. Pereira, Fabricio L. Brasil, Simone Gallo, Anthony A. Lin, Angelo K. Takigami, Maria A. Aratanha, Sanjay Joshi, Hannes Bleuler, Gordon Cheng, Alan Rudolph & Miguel A. L. Nicolelis
											</div>

											<div className="journal">
												<strong>Scientific Journal</strong>: Scientific Reports volume 6, Article number: 30383
											</div>

											<div className="published">
												<strong>Publicação</strong>: 11 Aug 2016
											</div>

											<div className="doi">
												<strong>DOI</strong>: https://doi.org/10.1038/srep30383
											</div>

											<div className="borderBottomArticle"></div>
										</div>
									</a>
								</div>
							</ScientificPosters>

							<ScientificPosters>
								<h3>{conferenceArticlesSection[0]?.title}</h3>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1109/ROBIO.2018.8665270"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<h4>
												Closed-Loop Functional Electrical Stimulation for Gait Training for Patients with Paraplegia
											</h4>

											<div className="authorsArticle">
												M. Bouri, A. Selfslagh, D. Campos, S. Yonamine, A. Donati, and S. Shokur (2019)
											</div>

											<div className="eventConference">
										In 2018 IEEE International Conference on Robotics and Biomimetics (ROBIO), pp. 1489-1495. IEEE.
									</div>
										</div>
									</a>
								</div>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1007/978-3-030-00329-6_13"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<h4>
												Exoskeletons as Mechatronic Design Example
											</h4>

											<div className="authorsArticle">
												B. Hannes, T. Vouga, A. Ortlieb, R. Baud, J. Fasola, J. Olivier, S. Shokur, and M. Bouri (2018)
											</div>

											<div className="eventConference">
												New Trends in Medical and Service Robotics (MESROB), pp. 109-117. Springer, Cham.
											</div>
										</div>
									</a>
								</div>

								<div className="articleScientificPoster">
									<a
										href="https://doi.org/10.1109/SEARIS.2014.7152802"
										target= "_blank"
										rel="noopener noreferrer"
									>
										<div>
											<h4>
												Enabling Closed-Source Applications for Virtual Reality via OpenGL Intercept-based Techniques
											</h4>

											<div className="authorsArticle">
												<span>D. J. Zielinski, R. P. McMahan, S. Shokur, E. Morya, and R. Kopper (2014)</span>
											</div>

											<div className="eventConference">
												IEEE 7th Workshop on Software Engineering and Architectures for Realtime Interactive Systems (SEARIS), pp. 1–6.

												Conference Location: Minneapolis, MN, USA
											</div>
										</div>
									</a>
								</div>
							</ScientificPosters>

							<ScientificPosters>
								<h3>{scientificMeetingsTitle[0]?.title}</h3>

								<div className="articleScientificPoster">
									<span>
										Shokur, S.; Asnis, F.; Almeida S.; Nicolelis, M.A.L. <strong>The peripersonal space representation in paraplegic patients depends on the level of lower-limb residual neurological functions.</strong> In: 48th Society for Neuroscience, 2018, San Diego.
									</span>
								</div>

								<div className="articleScientificPoster">
									<span>
										Shokur, S.; Donati, A.R.C; Nicolelis, M.A.L. <strong>Long-term training with no-invasive brain machine-interfaces and locomotion promotes neurological improvements in patients with chronic complete paraplegia: a pilot clinical trial</strong>. In: 47th Society for Neuroscience, 2017, Washington.
									</span>
								</div>

								<div className="articleScientificPoster">
									<span>
										Selfslagh, A.; Shokur, S.; Donati, A. R. C; Campos, D. S. F; Almeida, S. B de; Padula, N.; Bleuler, H.; Bouri, M.; Nicolelis, M.A.L. <strong>Locomotion training with closed-loop brain-machine interface and lower-limb functional electrical stimulation for complete paraplegic patients</strong>. In: 47th Society for Neuroscience, 2017, Washington.
									</span>
								</div>

								<div className="articleScientificPoster">
									<span>
										Campos, Debora S. F.; Selfslagh, Aurélie; Shokur, Solaiman; Donati, Ana R. C.; Fisher, Dora; Bouri, Mohamed; Bleuler, Hannes; Nicolelis, Miguel A. L. <strong>Developing a new synergic muscle contraction gait model produced by surface functional electrical stimulation (FES) in humans after complete spinal cord injury (SCI)</strong>. In: Annual Meeting of the International Functional Electrical Stimulation Society (IFESS), 2017, London.
									</span>
								</div>

								<div className="hiddenPosters">
									<div className="articleScientificPoster">
										<span>
											Donati, Ana R. C.; Shokur, Solaiman; Campos, Debora S. F.; Nicolelis, Miguel A. L. <strong>Development of a New Motor Assessment for Spinal Cord Injury Patients.</strong> In: International Neurorehabilitation Symposium (INRS), 2017, London.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Nicolelis, Miguel A. L.; Donati, Ana R. C.; Shokur, S.; Morya, E. <strong>Brain-Machine-Interface Based Neurorehabiltation Induces Partial Neurological Recovery in Paraplegic Patients.</strong> In: 9th World Congress for Neurorehabilitation, 2016, Philadelphia.
										</span>
									</div>
									<div className="articleScientificPoster">
										<span>
											Donati, A.R.C.; Shokur S.; Campos, D. S. F.; Pires, Cristhiane G.; Fischer, D.; Morya, E.; Nicolelis, M.A.L. <strong>Improvement of Trunk Stability in Chronic Paraplegic Patients After Long-Term Training With Robotic Orthotic Trainers.</strong> In: 9th World Congress for Neurorehabilitation, 2016, Philadelphia.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Shokur S.; Donati, A. R. C.; Moioli, R. C.; Nicolelis, M. A. L. <strong>Tactile Feedback Restoration Using Sensory Substitution In Chronic Paraplegic Patients.</strong> In: 9th World Congress for Neurorehabilitation, 2016, Philadelphia.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											V. Braga, A. Donati, S. Shokur, M. Nicolelis. <strong>A melhora da função intestinal dos pacientes com lesão medular crônica submetidos a treinamento de neuroreabilitação de longo prazo.</strong> In: 25° Congresso Brasileiro de Medicina Física e Reabilitação, 2016, São José do Rio Preto.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											D. Campos, A. Donati, D. Fisher, S. Shokur, M. Nicolelis <strong>Programa de reabilitação ativa para lesão medular completa: impacto sobre a recuperação neurológica motora.</strong> In: 25° Congresso Brasileiro de Medicina Física e Reabilitação, 2016, São José do Rio Preto.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											P. Augusto, C. Gitti, A. Donati, S. Shokur, M. Nicolelis <strong>Mudanças na imagem corporal de pacientes com lesão medular crônica e motoramente completa após experimento com realidade virtual.</strong> In: 25° Congresso Brasileiro de Medicina Física e Reabilitação, 2016, São José do Rio Preto.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Shokur, S; Gallo, S; Moioli, R; Bouri, M; Morya, E; Bleuler, H; Nicolelis, Miguel A. L. <strong>Inducing paraplegic patients to perceive distinct ground textures using tactile feedback generated by virtual feet.</strong> In: 45th Society for Neuroscience Meeting, 2015, Chicago.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Moioli, Renan Cipriano; Shokur, S.; Gallo, S.; Brasil, Fabricio Lima; Morya, Edgard; Nicolelis, Miguel A. L. <strong>Cortical incorporation of virtual legs in spinal cord injured patients.</strong> In: 45th Society for Neuroscience Meeting, 2015, Chicago.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Aratanha, Maria Adelia; Shokur, S.; Brasil, Fabricio Lima; Donati, A. C.; Gallo, S.; Morya, Edgard; Nicolelis, Miguel A. L. <strong>Closed loop brain controlled avatar training for locomotion with spinal cord injured patients.</strong> In: 45th Society for Neuroscience Meeting, 2015, Chicago.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Donati, A. C.; Shokur, S.; Morya, Edgard; Gitti, C. M.; Augusto, P. B.; Campos, D.; Yoshihara, D.; Nicolelis, Miguel A. L. <strong>Twelve months of physical rehabilitation protocol integrating brain controlled locomotor training and tactile feedback for patients with chronic spinal cord injury.</strong> In: 45th Society for Neuroscience Meeting, 2015, Chicago.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Brasil, Fabricio Lima; Shokur, S.; Aratanha, Maria Adelia; Moioli, Renan Cipriano; Donati, A. C.; Morya, Edgard; Nicolelis, Miguel A. L. <strong>Walk using single leg control at BMI-driven exoskeleton.</strong> In: 45th Society for Neuroscience Meeting, 2015, Chicago.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Brasil, F.; Moioli, R. C.; Shokur, S.; Fast, K.; Lin, A.; Peretti, N.; Takigami, A.; Lyons, K.; Zielinski, D.; Sawaki, L.; Joshi, S. ; Morya, Edgard; Nicolelis, Miguel A. L. <strong>The Walk Again Project: An EEG/EMG training paradigm to control locomotion.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Lin, A. ; Schwarz, D. ; Sellaouti, R. ; Shokur, S. ; Moioli, R. ; Brasil, F. ; Fast, K. ; Peretti, N. ; Takigami, A. ; Gallo, S. ; Lyons, K. ; Mittendorfer, P. ; Lebedev, M. ; Joshi, S. ; Cheng, G. ; Morya, Edgard ; Rudolph, A. ; Nicolelis, Miguel A. L. <strong>The walk again project: Brain-controlled exoskeleton locomotion.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Moioli, R. C.; Brasil, F.; Shokur, S.; Lin, A.; Fast, K.; Peretti, N.; Takigami, A.; Schwarz, D.; Morya, Edgard; Nicolelis, Miguel A. L <strong>The Walk Again Project: Analysis of brain activity of spinal cord injury patients during training with a BMI.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Nicolelis, Miguel A. L.; Shokur, S.; Lin, A.; Moioli, R. C.; Brasil, F.; Peretti, N.; Fast, K.; Takigami, A.; Morya, Edgard; Cheng, G.; Sawaki, L.; Kopper, R.; Schwarz, D.; Gallo, S.; Lebedev, M.; Joshi, S.; Bleuler, H.; Rudolph, A. <strong>The Walk Again Project: Using a Brain-Machine Interface for establishing a bi-directional Interaction between paraplegic subjects and a lower limb exoskeleton.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Sawaki, L.; Donati, A. C.; Nogueira, A. N.; Garabello, C.; Gitti, C. M.; Campos, D.; Yoshihara, D.; Pereira, G. A.; Araujo, I.; Augusto, P. B.; Tripodi, S.; Morya, Edgard; Nicolelis, Miguel A. L. <strong>Novel rehabilitative strategy to facilitate EEG-triggered locomotor training in chronic spinal cord injury patients: Preliminary results of an ongoing study.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Shokur, S.; Gallo, S.; Olivier, J.; Takigami, A.; Lin, A.; Fast, K.; Moioli, R.; Brasil, F.; Morya, Edgard; Cheng, G.; Bleuler, H.; Nicolelis, Miguel A. L. <strong>The walk again project: Sensory feedback for brain controlled exoskeleton.</strong> In: 44th Society for Neuroscience Meeting, 2014, Washington.
										</span>
									</div>

									<div className="articleScientificPoster">
										<span>
											Zielinski, D.; McMahan, R. P.; Shokur, S.; Morya, Edgard; Kopper, R. <strong>Enabling Closed-Source Applications for Virtual Reality via OpenGL Intercept Techniques.</strong> In: 7th Workshop on Software Engineering and Architectures for Realtime Interactive Systems, 2014, Minneapolis. v.7
										</span>
									</div>
								</div>

								<button onClick={handlePosters}>
									{(!showPosters) ? showMore[0]?.title: showLess[0]?.title}
								</button>
							</ScientificPosters>
						</Content>
					</ResponsiveContainer>
				</Section>

				<Footer />
			</Bg>
		</Container>
	);
}

export default WAP;
