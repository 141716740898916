import React from 'react';

import { Container, Spinner } from './styles';

import logoAASDAP from '../../assets/aasdap-logo.png';

const LoadingPage: React.FC = () => {
  return (
    <Container>
      <img src={logoAASDAP} alt="AASDAP" />

      <Spinner>
        <div />
        <div />
        <div />
        <div />
      </Spinner>
    </Container>
  );
}

export default LoadingPage;
