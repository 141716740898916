import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';
import History from '../pages/History';
import Units from '../pages/Units';
import Projects from '../pages/Projects';
import WAP from '../pages/WAP';
import NIABS from '../pages/NIABS';
import Scientific_Publications from '../pages/ScientificPublications';
import Press from '../pages/Press';
import News from '../pages/News';
import ContactUs from '../pages/ContactUs';

const Routes: React.FC = () => (
	<Switch>
		<Route path="/" exact component={Home} />

		<Route path="/institucional/sobre-aasdap" component={AboutUs} />
		<Route path="/institucional/historia" component={History} />
		<Route path="/institucional/unidades" component={Units} />
		<Route path="/institucional/projetos" component={Projects} />

		<Route path="/projeto-andar-de-novo" component={WAP} />

		<Route path="/instituto-nicolelis-de-estudos-avançados-do-cerebro" component={NIABS} />

		<Route path="/producao-cientifica" component={Scientific_Publications} />

		<Route path="/midia/imprensa" component={Press} />
		<Route path="/midia/noticias" exact component={News} />
		<Route path="/midia/noticias/:id" component={News} />

		<Route path="/contato" component={ContactUs} />

		<Route component={Home} />
	</Switch>
);

export default Routes;
