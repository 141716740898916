import React from 'react';
import { Link } from 'react-router-dom';
import { RiCalendar2Line } from 'react-icons/ri'
import { format } from 'date-fns';

import { Container, Cover, TextCard } from './styles';

interface ICardsItemProps {
  id: number;
  cover?: string;
  title?: string;
  slug?: string;
  publicationDate?: Date;
}

const NewsCard: React.FC<ICardsItemProps>= ({
  id,
  cover,
  title,
  slug,
  publicationDate
}) => {
  const date = publicationDate && format(new Date(publicationDate), 'dd/MM/yyyy');

  return (
    <Link to={`/midia/noticias/${id}`}>
      <Container>
        <Cover>
          <img src={cover} alt={title}/>
        </Cover>

        <TextCard>
          <h2>{title}</h2>

          <span>
            <RiCalendar2Line size={18}/> {date}
          </span>
        </TextCard>
      </Container>
    </Link>
  );
}

export default NewsCard;
