import React from 'react';

import {Container} from './styles';

const Footer: React.FC = () => (
  <Container>
    <span>Copyright © AASDAP - 2020. All rights reserved.</span>
  </Container>
);

export default Footer;
