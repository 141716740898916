import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: #ffffff8f;
  color: #000;

  border-radius: 36px;
  border-left: 5px solid #F09F30;
  border-right: 5px solid #F09F30;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:hover {
    position: relative;
    top: -10px;
  }

  @media (max-width: 446px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Cover = styled.div`
  padding: 10px auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-direction: center;
  justify-content: center;

  img {
    width: 200px;
    height: 180px;
    border-radius: 10px;

    @media (max-width: 554px) {
      width: 150px;
      height: 135px;
    }

    @media (max-width: 446px) {
      margin-bottom: 10px;
    }
  }
`

export const TextCard = styled.div`
  padding: 0 15px 0 20px;

  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  span {
    font-size: 15px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    & + span {
      margin-top: 8px;
    }
  }
`
