import styled from 'styled-components';


export const Container = styled.main`
	width: 100vw;
	overflow-x: hidden;
	background-color: #fff;

	#icmaqWap {
		@media only screen and (max-width: 576px) {
			margin-top: -50px;
		}
	}
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background-position: 0px 25px;
	}

	@media only screen and (min-width: 768px) {
		background-position: initial;
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
		text-align: center;

		@media screen and (max-width: 576px) {
			padding: 10px 0px;
		}
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;

		background-color: #1E2B6E;
	}

	div.subtitle {
		width: 300px;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		align-items: center;

		span {
			& + h2 {
				margin-bottom: 30px;
			}
		}
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}

	&.clearBottom {
		margin-bottom: 0px;
		padding-bottom: 0px;
	}

	& + section {
		margin-top: 40px;
	}
`;

export const Section = styled.section`
	margin-bottom: 50px;
	display: flex;
	place-content: center;
`;

export const Content = styled.div`
	width: 100%;
	max-width: 1511px;
	margin: auto auto;
`;

export const Logo = styled.div`
	margin-top: 80px;
	margin-bottom: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	img {
		width: 100%;
		max-width: 320px;
		height: auto;
		margin-bottom: 20px;
	}

	@media (min-width: 768px) {
		flex-direction: row;

		img {
			max-width: 340px;
		}
	}

	@media (min-width: 920px) {
		img {
			max-width: 400px;
		}
	}
`
